import { useQuery } from '@tanstack/vue-query'
import { Client } from 'typesense'
import { computed, MaybeRef, Ref } from 'vue'

import type { AddressBookAssignment } from '@epostbox/db/search'
import { ServiceError } from '@epostbox/shared/errors'

import { useMyContact } from '@modules/workbench/address-book/composables/contacts/use-my-entry'

import { useSearchKey } from './use-search-key'

const addressbookCollection = 'addressbook'

export const contactsQueryKey = 'contacts-search'

export interface ContactSearchInput {
  q?: string
  filterBy?: {
    authenticatedBy?: string
    nolas?: string
    isGroupOrOrg?: boolean
  }
  sort: {
    key: string
    order: 'asc' | 'desc'
  }
  folderId?: string | string[]
  isMyOrganizationFolder?: boolean
  isBlockedFolder?: boolean
  limit: number
  page: number
}

export function useContactSearch(
  search: MaybeRef<ContactSearchInput>,
  options?: { refetch?: boolean; isMyOrganization?: boolean }
) {
  const { searchCreds } = useSearchKey()
  const { myContact } = useMyContact()

  const searchClient = computed(() => {
    if (!searchCreds.value) return

    const url = new URL(searchCreds.value.url)

    return new Client({
      nodes: [
        {
          host: url.hostname,
          port: url.protocol === 'https:' ? 443 : Number(url.port || 80),
          protocol: url.protocol.replace(':', ''),
        },
      ],
      apiKey: searchCreds.value.keys.addressReadKey,
      connectionTimeoutSeconds: 10,
    })
  })

  const buildFilterString = (
    folderId: string | string[],
    filterBy?: ContactSearchInput['filterBy'],
    isMyOrganizationFolder?: ContactSearchInput['isMyOrganizationFolder'],
    showBlocked: boolean = false
  ): string => {
    // If isGroupOrOrg is true, only return the type filter
    if (filterBy?.isGroupOrOrg) {
      return 'type:in:[GROUP,ORGANIZATION]'
    }

    const filters: string[] = []

    if (!showBlocked) {
      filters.push('handshakeStatus:!="blocked"')
    }

    if (folderId) {
      if (Array.isArray(folderId)) {
        filters.push(`folder.id:=[${folderId}]`)
      } else {
        filters.push(`folder.id:=${folderId}`)
      }
    }

    if (!filterBy) return filters.join(' && ')

    if (filterBy.authenticatedBy) {
      filters.push(`identityType:=${filterBy.authenticatedBy}`)
    }

    if (filterBy.nolas === 'true') {
      isMyOrganizationFolder
        ? filters.push(`entry.userId:!=missing && entry.workspaceJoined:=true`)
        : filters.push(`entry.userId:!=missing`)
    }

    if (filterBy.nolas === 'false') {
      filters.push(`entry.userId:=missing`)
    }

    return filters.join(' && ')
  }

  const buildSortString = (sort: ContactSearchInput['sort']): string => {
    return sort.key && `entry.${sort.key}:${sort.order}`
  }

  const pinnedHits = (folderId: string) => {
    if (myContact.value?.folder.id !== folderId) return []
    return [`${myContact.value?.id}:1`]
  }

  const {
    data: searchResult,
    error,
    ...queryRest
  } = useQuery({
    queryKey: [contactsQueryKey, search] as const,
    enabled: () => !!searchCreds.value,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    // eslint-disable-next-line unicorn/no-nested-ternary
    refetchInterval: import.meta.env.DEV ? undefined : options?.refetch ? 2000 : undefined,
    queryFn: async ({ queryKey: [, search] }) => {
      const searchResults = await searchClient
        .value!.collections<AddressBookAssignment>(addressbookCollection)
        .documents()
        .search(
          {
            q: search.q ?? '*',
            per_page: search.limit,
            page: search.page,
            pinned_hits: pinnedHits(search.folderId as string),
            filter_curated_hits: true,
            query_by: 'entry.name,entry.properties',
            filter_by: buildFilterString(
              search.folderId!,
              search.filterBy,
              search.isMyOrganizationFolder,
              search.isBlockedFolder
            ),
            sort_by: buildSortString(search.sort),
            num_typos: '1',
          },
          {}
        )

      return searchResults
    },
  })

  return { searchResult, error: error as Ref<ServiceError | null>, ...queryRest }
}
