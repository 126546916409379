<script setup lang="ts">
import { SelectIcon, SelectTrigger } from 'radix-vue'
import { inject } from 'vue'

import { cn, groupStyleClasses } from '../../utils'

import { selectVariants, selectWrapperVariants, type CustomSelectTriggerProps } from './'

const props = withDefaults(defineProps<CustomSelectTriggerProps>(), {
  name: '',
  class: '',
  label: '',
  invalid: false,
  error: '',
  value: undefined,
  triggerClasses: '',
  wrapperClasses: '',
  dataE2e: '',
  color: 'beige',
  required: false,
  hideErrorIcon: false,
})

const isInputGroup = inject<boolean>('input-group', false)

const modelValue = defineModel<string | undefined>()
</script>

<template>
  <div
    class="relative flex items-center"
    :class="[
      cn(
        selectWrapperVariants({ color }),
        disabled ? 'cursor-not-allowed opacity-60' : 'cursor-text opacity-100',
        wrapperClasses ?? '',
        isInputGroup ? '!border-r-0 !border-transparent' : '',
        !isInputGroup && open ? 'border-primary-500' : '',
        !isInputGroup && error ? 'border-error-650' : '',
        groupStyleClasses(isInputGroup!)
      ),
      props.invalid ? 'ring-2 !ring-destructive placeholder:!text-destructive' : '',
    ]"
  >
    <SelectTrigger
      :class="cn('relative', selectVariants({ color }), triggerClasses ?? '', disabled ? 'pointer-events-none' : '', label && 'pt-5')"
      v-bind="dataE2e ? { 'data-e2e': dataE2e, ...props } : { ...props }"
    >
      <slot />
      <SelectIcon as-child>
        <div class="absolute right-4 top-1/2 ml-auto flex -translate-y-1/2 transform items-center space-x-2">
          <INolasAlertCircle v-if="error && !hideErrorIcon" class="h-6 w-6 min-w-6 text-error-650" />
          <INolasSlashCircle v-if="disabled" :class="cn('h-6 w-6 min-w-6 text-gray-875')" />
          <INolasChevronDown
            v-if="!disabled"
            :class="
              cn(
                'h-5 w-5 shrink-0 transform text-gray-1000 transition-all duration-200',
                open ? 'rotate-180' : 'rotate-0'
              )
            "
          />
        </div>
      </SelectIcon>
    </SelectTrigger>
    <label
      v-if="label"
      :for="name"
      :class="
        cn(
          'pointer-events-none absolute left-4 origin-[0] transform font-medium text-gray-625 duration-300',
          modelValue ? '-translate-y-3 text-xs' : 'translate-y-0 text-sm',
          disabled ? 'pointer-events-none text-gray-warm-850' : '',
          error ? 'text-error-650' : ''
        )
      "
    >
      {{ label }}
      <span v-if="required">*</span>
    </label>
  </div>
</template>
