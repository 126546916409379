import { idPrefix } from '@epostbox/shared/database'

// Auxiliary object (enum-like) used in avoiding potential
// duplicates of id prefix from arbitrary strings
const Prefix = {
  workspace: 'wrsp',
  user: 'user',
  interfaceFtpUser: 'iftp',
  interfaceRestApiUser: 'irst',
  flowState: 'flow',
  role: 'rol',
  userRole: 'urol',
  identity: 'id',
  profile: 'prof',
  refreshToken: 'tokn',
  samlProvider: 'saml',
  session: 'sesh',
  ssoDomain: 'ssod',
  ssoProvider: 'ssop',
  workspaceMember: 'wrsm',
  permission: 'perm',
  scimToken: 'scim',
  customerData: 'cusd',
  emailAccount: 'eacc',
  userIdentity: 'usid',
} as const

export const $workspaceId = idPrefix(Prefix.workspace)
export const WorkspaceID = $workspaceId.schema
export type WorkspaceID = typeof $workspaceId.schema._type

export const $userId = idPrefix(Prefix.user)
export const UserID = $userId.schema
export type UserID = typeof $userId.schema._type

export const $interfaceFtpUserId = idPrefix(Prefix.interfaceFtpUser)
export const InterfaceFTPUserID = $interfaceFtpUserId.schema
export type InterfaceFTPUserID = typeof $interfaceFtpUserId.schema._type

export const $interfaceRestApiUserId = idPrefix(Prefix.interfaceRestApiUser)
export const InterfaceRestApiUserID = $interfaceRestApiUserId.schema
export type InterfaceRestApiUserID = typeof $interfaceRestApiUserId.schema._type

export const $flowStateId = idPrefix(Prefix.flowState)
export const FlowStateID = $flowStateId.schema
export type FlowStateID = typeof $flowStateId.schema._type

export const $roleId = idPrefix(Prefix.role)
export const RoleID = $roleId.schema
export type RoleID = typeof $roleId.schema._type

export const $userRoleId = idPrefix(Prefix.userRole)
export const UserRoleID = $userRoleId.schema
export type UserRoleID = typeof $userRoleId.schema._type

export const $identityId = idPrefix(Prefix.identity)
export const IdentityID = $identityId.schema
export type IdentityID = typeof $identityId.schema._type

export const $profileId = idPrefix(Prefix.profile)
export const ProfileID = $profileId.schema
export type ProfileID = typeof $profileId.schema._type

export const $refreshTokenId = idPrefix(Prefix.refreshToken)
export const RefreshTokenID = $refreshTokenId.schema
export type RefreshTokenID = typeof $refreshTokenId.schema._type

export const $samlProviderId = idPrefix(Prefix.samlProvider)
export const SAMLProviderID = $samlProviderId.schema
export type SAMLProviderID = typeof $samlProviderId.schema._type

export const $sessionId = idPrefix(Prefix.session)
export const SessionID = $sessionId.schema
export type SessionID = typeof $sessionId.schema._type

export const $ssoDomainId = idPrefix(Prefix.ssoDomain)
export const SSODomainID = $ssoDomainId.schema
export type SSODomainID = typeof $ssoDomainId.schema._type

export const $ssoProviderId = idPrefix(Prefix.ssoProvider)
export const SSOProviderID = $ssoProviderId.schema
export type SSOProviderID = typeof $ssoProviderId.schema._type

export const $workspaceMemberId = idPrefix(Prefix.workspaceMember)
export const WorkspaceMemberID = $workspaceMemberId.schema
export type WorkspaceMemberID = typeof $workspaceMemberId.schema._type

export const $permissionId = idPrefix(Prefix.permission)
export const PermissionID = $permissionId.schema
export type PermissionID = typeof $permissionId.schema._type

export const $scimTokenId = idPrefix(Prefix.scimToken)
export const ScimTokenID = $scimTokenId.schema
export type ScimTokenID = typeof $scimTokenId.schema._type

export const $customerDataId = idPrefix(Prefix.customerData)
export const CustomerDataID = $customerDataId.schema
export type CustomerDataID = typeof $customerDataId.schema._type

export const $emailAccountId = idPrefix(Prefix.emailAccount)
export const EmailAccountID = $emailAccountId.schema
export type EmailAccountID = typeof $emailAccountId.schema._type

export const $userIdentityId = idPrefix(Prefix.userIdentity)
export const UserIdentityId = $userIdentityId.schema
export type UserIdentityId = typeof $userIdentityId.schema._type
