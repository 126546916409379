import { sql, SQL } from 'drizzle-orm'
import { AnyPgColumn, customType } from 'drizzle-orm/pg-core'

export const ltree = customType<{ data: string }>({
  dataType() {
    return 'ltree'
  },
})
export function lower(email: AnyPgColumn): SQL {
  return sql`lower(${email})`
}
