import { relations } from 'drizzle-orm'
import { boolean, pgEnum, varchar } from 'drizzle-orm/pg-core'
import { createSelectSchema, createInsertSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, timestamps } from '@epostbox/shared/database'

import { costCenter } from '../cost-center'

import { authSchema } from './.auth-schema'
import { $workspaceId, WorkspaceID } from './_ids'
import { samlProviders } from './saml-provider'
import { scimTokens } from './scim-token'
import { ssoDomains } from './sso-domain'
import { workspaceMembers } from './workspace-member'

export const EinvoiceType = z.enum(['ZUGFERD', 'FACTURX'])
export type EinvoiceType = z.infer<typeof EinvoiceType>

export const EinvoiceChannel = z.enum(['NOLAS', 'POST', 'NOLAS_AND_POST'])
export type EinvoiceChannel = z.infer<typeof EinvoiceChannel>

export const eInvoiceTypeEnum = pgEnum('einvoice_type', [EinvoiceType.Enum.FACTURX, EinvoiceType.Enum.ZUGFERD])
export const eInvoiceChannelEnum = pgEnum('einvoice_channel', [
  EinvoiceChannel.Enum.NOLAS,
  EinvoiceChannel.Enum.POST,
  EinvoiceChannel.Enum.NOLAS_AND_POST,
])

export const workspaces = authSchema.table('workspaces', {
  id: id<WorkspaceID>($workspaceId.prefix),
  name: varchar('name').notNull(),
  approved: boolean('approved').default(false),
  eInvoiceType: eInvoiceTypeEnum('e_invoice_type'),
  eInvoiceChannel: eInvoiceChannelEnum('e_invoice_channel'),
  ...timestamps(),
})

export const WorkspaceRecord = createSelectSchema(workspaces, { id: WorkspaceID })
export interface WorkspaceRecord extends z.infer<typeof WorkspaceRecord> {}

export const WorkspaceRecordCreate = createInsertSchema(workspaces).omit({
  id: true,
  deletedAt: true,
  updatedAt: true,
  createdAt: true,
})
export interface WorkspaceRecordCreate extends z.infer<typeof WorkspaceRecordCreate> {}

export const workspaceRelations = relations(workspaces, ({ many }) => ({
  samlProviders: many(samlProviders),
  ssoDomains: many(ssoDomains),
  scimTokens: many(scimTokens),
  members: many(workspaceMembers),
  costCenters: many(costCenter),
}))
