import { z } from '@hono/zod-openapi'
import { relations } from 'drizzle-orm'
import { boolean, text, timestamp, pgEnum, uniqueIndex, varchar, integer } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { id, lower, refId, timestamps } from '@epostbox/shared/database'

import { addressEntry } from '../address-book'

import { authSchema } from './.auth-schema'
import { $userId, UserID, UserIdentityId } from './_ids'
import { identities } from './identity'
import { profiles } from './profile'
import { refreshTokens } from './refresh-token'
import { userIdentity } from './user-identity'
import { userRoles } from './user-role'
import { workspaceMembers } from './workspace-member'

export const UserStatus = z.enum(['ACTIVE', 'SUSPENDED'])
export const userStatus = pgEnum('user_status', [UserStatus.Enum.ACTIVE, UserStatus.Enum.SUSPENDED])

export const users = authSchema.table(
  'user',
  {
    id: id<UserID>($userId.prefix),
    email: text('email').notNull(),
    encryptedPassword: text('encrypted_password').notNull(),

    identityId: refId<UserIdentityId>('identity_id').references(() => userIdentity.id, { onDelete: 'restrict' }),

    isSuperAdmin: boolean('is_super_admin').default(false),

    confirmationToken: text('email_confirmation_token'),
    confirmationSentAt: timestamp('email_confirmation_sent_at'),

    passwordResetToken: text('password_reset_token'),
    passwordResetSentAt: timestamp('password_reset_sent_at'),

    accountSource: varchar('account_source', {
      enum: ['register', 'invitation', 'scim', 'login_assignment', 'generated', 'customer'],
    }).default('register'),

    /** Temporary email address set when the user wishes to update their email. Unused. */
    emailNew: text('email_new'),
    emailNewConfirmationToken: text('email_new_confirmation_token'),
    emailNewConfirmationSentAt: timestamp('email_new_confirmation_sent_at'),

    /** Customer confirmation token to verify */
    customerConfirmationToken: text('customer_confirmation_token'),

    lockedAt: timestamp('locked_at'),

    // User status - if suspended, the user cannot log in
    userStatus: userStatus('user_status').default(UserStatus.Enum.ACTIVE),
    loginAttemptsLeft: integer('login_attempts_left').notNull().default(10),
    loginRetryAfter: timestamp('login_retry_after'),

    ...timestamps(),
  },
  table => ({
    emailUniqueIndex: uniqueIndex('email_unique_index').on(lower(table.email)),
  })
)

const UserSchema = {
  id: UserID,
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date(),
  identityId: UserIdentityId,
  confirmationSentAt: z.coerce.date(),
  passwordResetSentAt: z.coerce.date(),
  emailNewConfirmationSentAt: z.coerce.date(),
  lockedAt: z.coerce.date().nullish(),
  userStatus: UserStatus,
  loginAttemptsLeft: z.coerce.number(),
  loginRetryAfter: z.coerce.date().nullish(),
}

export const UserRecord = createSelectSchema(users, UserSchema)
export interface UserRecord extends z.infer<typeof UserRecord> {}

export const UserRecordCreate = createInsertSchema(users, UserSchema).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
  emailNew: true,
  emailNewConfirmationSentAt: true,
  emailNewConfirmationToken: true,
})
export interface UserRecordCreate extends z.infer<typeof UserRecordCreate> {}

export const userRelations = relations(users, ({ one, many }) => ({
  identities: many(identities),
  refreshTokens: many(refreshTokens),
  memberOf: many(workspaceMembers, { relationName: 'memberOf' }),
  inviterOf: many(workspaceMembers, { relationName: 'inviterOf' }),
  profile: one(profiles),
  roles: many(userRoles),
  userIdentity: one(userIdentity, {
    fields: [users.identityId],
    references: [userIdentity.id],
  }),
  addressEntry: one(addressEntry, {
    fields: [users.id],
    references: [addressEntry.handshakenUserId],
  }),
}))
