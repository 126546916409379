import { jsonb, pgTable, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId } from '@epostbox/shared/database'

import { $deliveryId, DeliveryID, DocumentAssignmentID } from './_ids'
import { UserID } from './auth/_ids'
import { users } from './auth/user'
import { Channel, channelEnum } from './documents-assignments'

export const delivery = pgTable('delivery', {
  id: id<DeliveryID>($deliveryId.prefix),
  subject: text('subject').notNull(),
  body: text('body').notNull(),
  sender: refId<UserID>('sender')
    .references(() => users.id, { onDelete: 'no action' })
    .notNull(),
  channel: channelEnum('channel').notNull(),
  recipients: jsonb('recipients').notNull().$type<EmailRecipient[] | NolasRecipient[] | PostRecipient[]>(),
  docs: jsonb('docs').notNull().$type<DocumentAssignmentID[]>(),
})

// email
interface EmailRecipient {
  email: string
}
interface EmailRecipientsAndChannel {
  channel: Channel.Email
  recipients: EmailRecipient[]
}

// nolas
interface NolasRecipient {
  userId: UserID
}
interface NolasRecipientsAndChannel {
  channel: Channel.Nolas
  recipients: NolasRecipient[]
}

// post
interface PostRecipient {
  // todo
}
interface PostRecipientsAndChannel {
  channel: Channel.Post
  recipients: PostRecipient[]
}

// general

type RecipientsAndChannel = EmailRecipientsAndChannel | NolasRecipientsAndChannel | PostRecipientsAndChannel

const DeliveryRecord = createSelectSchema(delivery, {
  id: DeliveryID,
  sender: UserID,
  docs: z.array(DocumentAssignmentID),
})

export type DeliveryRecord = Omit<z.infer<typeof DeliveryRecord>, 'channel' | 'recipients'> & RecipientsAndChannel

const CreateDeliveryRecord = createInsertSchema(delivery, {
  id: DeliveryID,
  sender: UserID,
  docs: z.array(DocumentAssignmentID),
})

export type CreateDeliveryRecord = Omit<z.infer<typeof CreateDeliveryRecord>, 'channel' | 'recipients'> &
  RecipientsAndChannel
