import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { MultiSearchRequestSchema } from 'typesense/lib/Typesense/MultiSearch'
import { MaybeRef, Ref } from 'vue'

import { Space, spaceEnum } from '@epostbox/db/schema'
import type { Document } from '@epostbox/db/search'
import { ServiceError } from '@epostbox/shared/errors'

import { useSearchClient } from '@composables/search/use-search-client'

import { Filter } from '@modules/workbench/composables/use-table-state'

const documentsCollection = 'document_assignments'

export interface DocumentsSearchInput {
  q?: string
  page?: number
  limit?: number
  space?: Space
  filterBy?: Filter
  sortBy?: string
}

export function useDocumentSearch(search: MaybeRef<DocumentsSearchInput>, options?: { refetch?: boolean }) {
  const { searchCreds, searchClient } = useSearchClient()

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const filterBy = (filters?: Filter) =>
    Object.entries(filters || {}).flatMap(([key, value]) => (value ? `${key} := ${value}` : []))

  const {
    data: searchResult,
    error,
    ...queryRest
  } = useQuery({
    queryKey: ['search', search] as const,
    enabled: () => !!searchCreds.value,
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    // prettier-ignore
    refetchInterval: import.meta.env.DEV ? undefined : (options?.refetch ? 2000 : undefined),
    queryFn: async ({ queryKey: [, search] }) => {
      const filter_by = [
        `space := ${search.space ?? 'DRAFTS'}`,
        `(isBundle := true || (isBundle := false && parentId :!= doc_*))`, // TODO: this condition doesnt work
        ...filterBy(search.filterBy as Filter),
      ]
        .flat()
        .join(' && ')

      const searchResults = await searchClient
        .value!.collections<Document>(documentsCollection)
        .documents()
        .search(
          {
            q: search.q ?? '*',
            per_page: search.limit,
            page: search.page,
            query_by: 'name, subject, sender.name',
            filter_by,
            num_typos: '1',
            infix: ['fallback', 'off', 'off'],
            sort_by: search.sortBy,
          },
          {}
        )

      return searchResults
    },
  })

  return { searchResult, error: error as Ref<ServiceError | null>, ...queryRest }
}

export function useDocumentsCounts() {
  const { searchCreds, searchClient } = useSearchClient()

  const {
    data: documentsCounts,
    error,
    ...queryRest
  } = useQuery({
    queryKey: ['search-counts'] as const,
    enabled: () => Boolean(searchCreds),
    retry: 2,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: 5000,
    queryFn: async () => {
      const searchResult = await searchClient.value!.multiSearch.perform<Document[]>({
        searches: spaceEnum.enumValues.map<MultiSearchRequestSchema>(space => ({
          collection: documentsCollection,
          q: '*',
          filter_by: `space := ${space}`,
          include_fields: '_', // we don't need any fields, they would just increase the size of the response
        })),
      })

      const [drafts, inbox, sent, trash, folder] = searchResult.results

      return {
        drafts: drafts.found,
        inbox: inbox.found,
        sent: sent.found,
        trash: trash.found,
        folder: folder.found,
      }
    },
    initialData: {
      drafts: 0,
      inbox: 0,
      sent: 0,
      trash: 0,
      folder: 0,
    },
  })

  return { documentsCounts, error: error as Ref<ServiceError | null>, ...queryRest }
}

export function useInvalidateDocuments() {
  const queryClient = useQueryClient()

  const invalidateDocuments = async () => {
    await Promise.allSettled([
      queryClient.invalidateQueries({ queryKey: ['search'] }),
      queryClient.invalidateQueries({ queryKey: ['search-counts'] }),
    ])
  }

  return { invalidateDocuments }
}
