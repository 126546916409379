import { Client } from 'typesense'
import { computed } from 'vue'

import { useSearchKey } from '@composables/search/use-search-key'

export function useSearchClient() {
  const { searchCreds } = useSearchKey()

  const searchClient = computed(() => {
    if (!searchCreds.value) return

    const url = new URL(searchCreds.value.url)

    return new Client({
      nodes: [
        {
          host: url.hostname,
          port: url.protocol === 'https:' ? 443 : Number(url.port || 80),
          protocol: url.protocol.replace(':', ''),
        },
      ],
      apiKey: searchCreds.value.keys.documentKey,
      connectionTimeoutSeconds: 10,
    })
  })

  return { searchCreds, searchClient }
}
