import { relations } from 'drizzle-orm'
import { jsonb, pgEnum, pgTable, text, uniqueIndex } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { $handshakeId, AddressAssignmentID, DocumentAssignmentID, HandshakeId } from './_ids'
import { addressAssignment } from './address-book'
import { UserIdentityId } from './auth/_ids'
import { userIdentity } from './auth/user-identity'

export const handshakeStatus = pgEnum('status', [
  'pending',
  'sent_email',
  'sent_qr',
  'sent_nolas',
  'approved',
  'denied',
  'blocked',
])

export const mailboxPreference = pgEnum('mailboxPreference', ['postal', 'email', 'nolas'])

export const HandShakeStatus = z.enum(handshakeStatus.enumValues)
export type HandShakeStatus = z.infer<typeof HandShakeStatus>

export const MailboxPreference = z.enum(mailboxPreference.enumValues)
export type MailboxPreference = z.infer<typeof MailboxPreference>
export const DocIds = z.object({ docs: z.array(DocumentAssignmentID) })
export type DocIds = z.infer<typeof DocIds>
export const handshake = pgTable(
  'handshake',
  {
    id: id<HandshakeId>($handshakeId.prefix),
    sourceIdentityId: refId<UserIdentityId>('source_identity_id').references(() => userIdentity.id, {
      onDelete: 'cascade',
    }),
    targetIdentityId: refId<UserIdentityId>('target_identity_id').references(() => userIdentity.id, {
      onDelete: 'cascade',
    }),
    status: handshakeStatus('status').default('pending'),
    sourcePreference: mailboxPreference('sourcePreference'),
    targetPreference: mailboxPreference('targetPreference'),
    secretToken: text('secret_token'),
    addressAssignmentId: refId<AddressAssignmentID>('address_assignment_id')
      .references(() => addressAssignment.id)
      .notNull(),
    documentAssignmentIds: jsonb('document_assignment_ids').$type<DocIds>(),
    ...timestamps(),
  },
  table => ({
    identityUniqueIndex: uniqueIndex('identities_unique_index').on(table.sourceIdentityId, table.targetIdentityId),
  })
)

const HandshakeSchema = {
  id: HandshakeId,
  sourceIdentityId: UserIdentityId,
  targetIdentityId: UserIdentityId,
  status: HandShakeStatus,
  addressAssignmentId: AddressAssignmentID,
  documentAssignmentIds: DocIds,
}

export const handshakeRelations = relations(handshake, ({ one }) => ({
  addressAssignment: one(addressAssignment, {
    fields: [handshake.addressAssignmentId],
    references: [addressAssignment.id],
  }),
}))

export const HandshakeRecord = createSelectSchema(handshake, HandshakeSchema)
export type HandshakeRecord = z.infer<typeof HandshakeRecord>

export const HandshakeRecordCreate = createInsertSchema(handshake, HandshakeSchema)
export type HandshakeRecordCreate = z.infer<typeof HandshakeRecordCreate>
