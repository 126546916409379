import { cva } from 'class-variance-authority'

export { default as Badge } from './badge.vue'

export const badgeVariants = cva('rounded-full text-xs leading-5 flex items-center gap-x-2 font-medium', {
  variants: {
    variant: {
      primary: 'bg-brand-250 text-gray-1000',
      white: 'bg-white text-gray-1000',
      'light-yellow': 'bg-gray-75 text-gray-warm-700',
      beige: 'bg-gray-275 text-gray-warm-700',
      outline: 'border border-gray-500 text-gray-500',
    },
    size: {
      sm: 'py-0.5 px-2.5 text-xs leading-5',
      md: 'py-1 px-3 text-xs leading-5',
      lg: 'py-1 pl-1.5 pr-3 text-sm',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
  },
})
