import { text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $sessionId, SessionID, UserID } from './_ids'
import { users } from './user'

/** A user can have multiple session. e.g. on multiple devices. */
export const sessions = authSchema.table('session', {
  id: id<SessionID>($sessionId.prefix),
  userId: refId<UserID>('user_id')
    .references(() => users.id, { onDelete: 'cascade' })
    .notNull(),
  externalSessionId: text('external_session_id'),
  ...timestamps(),
})

export const SessionRecord = createSelectSchema(sessions, { id: SessionID, userId: UserID })
export interface SessionRecord extends z.infer<typeof SessionRecord> {}

export const SessionRecordCreate = createInsertSchema(sessions, { userId: UserID }).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface SessionRecordCreate extends z.infer<typeof SessionRecordCreate> {}
