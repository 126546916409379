import { VueQueryPlugin } from '@tanstack/vue-query'
import { createHead } from '@unhead/vue'
import { configure } from 'vee-validate'
import { createApp } from 'vue'
import VueLazyLoad from 'vue3-lazyload'
// i18n
import { createI18n } from 'vue-i18n'
import { createRouter } from 'vue-i18n-routing'
import { createWebHistory } from 'vue-router'

import de from '@epostbox/shared/locales/de.json'
import en from '@epostbox/shared/locales/en.json'
import '@epostbox/ui/css'

import { routes as workbench } from '@modules/workbench/routes'
import { routes as workspace } from '@modules/workspaces/routes'

import App from './app.vue'
import ibanFormat from './directives/iban-format.ts'
import numericInputIntegerNegatives from './directives/numeric-input-integer-negatives.ts'
import numericInputInteger from './directives/numeric-input-integer.ts'
import numericInput from './directives/numeric-input.ts'
import _404 from './views/404.vue'
import Index from './views/index.vue'

// VeeValidate
configure({
  validateOnInput: false,
  validateOnModelUpdate: false,
  validateOnChange: false,
  validateOnBlur: false,
})

const i18n = createI18n({
  legacy: false,
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  globalInjection: true,
  messages: {
    en,
    de,
  },
})

const router = createRouter(i18n, {
  version: 4,
  locales: ['en', 'de'],
  defaultLocale: localStorage.getItem('nolas_lang') || 'de',
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Index,
      name: 'home',
      redirect: {
        path: '/workbench',
      },
      meta: {
        title: 'home',
      },
    },
    {
      path: '/print-queue',
      component: () => import('./views/print-queue.vue'),
      name: 'print-queue',
    },
    {
      name: '404',
      path: '/:pathMatch(.*)*',
      component: _404,
      meta: {
        title: '404',
      },
    },
    workbench,
    ...workspace,
  ],
})

router.onError((error: unknown) => {
  if (
    error instanceof Error &&
    (error.message.includes('Failed to load chunk') ||
      error.message.includes('Loading chunk') ||
      error.message.includes('Unknown chunk'))
  ) {
    window.location.reload()
  }
})

const head = createHead()

createApp(App)
  .use(router)
  .use(i18n)
  .use(head)
  .use(VueQueryPlugin)
  .use(VueLazyLoad, {})
  .directive('numeric-input', numericInput)
  .directive('numeric-input-integer', numericInputInteger)
  .directive('iban-format', ibanFormat)
  .directive('numeric-input-integer-negatives', numericInputIntegerNegatives)
  .mount('#app')
