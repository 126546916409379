<script setup lang="ts">
import { SelectItem, SelectItemIndicator, type SelectItemProps, SelectItemText } from 'radix-vue'

import { cn } from '../../utils'

const props = defineProps<SelectItemProps & { class?: string }>()
</script>

<template>
  <SelectItem
    v-bind="props"
    :class="
      cn(
        'relative flex cursor-default select-none items-center rounded-lg p-3.5 pl-4 text-sm font-medium leading-4.5 text-gray-1000 outline-none hover:bg-gray-warm-150 data-[disabled]:pointer-events-none data-[highlighted]:bg-gray-warm-150 data-[highlighted]:text-gray-1000 data-[disabled]:opacity-50',
        $attrs.class ?? ''
      )
    "
  >
    <span class="absolute right-4 flex h-3.5 w-3.5 items-center justify-center">
      <SelectItemIndicator>
        <INolasCheck class="h-5 w-5 text-brand-600" />
      </SelectItemIndicator>
    </span>

    <SelectItemText
      class="flex w-80 items-center gap-2.5 overflow-hidden truncate text-ellipsis pr-6 font-medium leading-4.5 !text-gray-1000"
    >
      <slot />
    </SelectItemText>
  </SelectItem>
</template>
