import { relations } from 'drizzle-orm'
import { varchar, boolean, uniqueIndex } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $ssoDomainId, SSODomainID, SSOProviderID, WorkspaceID } from './_ids'
import { ssoProviders } from './sso-provider'
import { workspaces } from './workspace'

export const ssoDomains = authSchema.table(
  'sso_domain',
  {
    id: id<SSODomainID>($ssoDomainId.prefix),
    domain: varchar('domain').notNull(),
    ssoProviderId: refId<SSOProviderID>('sso_provider_id')
      .references(() => ssoProviders.id, { onDelete: 'cascade' })
      .notNull(),
    workspaceId: refId<WorkspaceID>('workspace_id')
      .references(() => workspaces.id, { onDelete: 'cascade' })
      .notNull(),
    isValid: boolean('is_valid').notNull().default(false),
    dnsTxtRecord: varchar('dns_txt_record').notNull(),
    ...timestamps(),
  },
  table => {
    return {
      domainIdx: uniqueIndex('domain_idx').on(table.domain),
    }
  }
)

export const SSODomainRecord = createSelectSchema(ssoDomains, {
  id: SSODomainID,
  ssoProviderId: SSOProviderID,
  workspaceId: WorkspaceID,
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
})
export interface SSODomainRecord extends z.infer<typeof SSODomainRecord> {}

export const SSODomainRecordCreate = createInsertSchema(ssoDomains, {
  ssoProviderId: SSOProviderID,
  workspaceId: WorkspaceID,
}).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface SSODomainRecordCreate extends z.infer<typeof SSODomainRecordCreate> {}

export const ssoDomainRelations = relations(ssoDomains, ({ one }) => ({
  workspace: one(workspaces, {
    fields: [ssoDomains.workspaceId],
    references: [workspaces.id],
  }),
}))
