import { CompleteOnboarding, CompleteOnboardingRequest } from '@services/keyflowz-api'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { Ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { ServiceError } from '@epostbox/shared/errors'
import { toast } from '@epostbox/ui/sonner'

import { contactsQueryKey } from '@composables/search/use-contact-search'
import { useUser } from '@composables/use-user'

export function useCompleteOnboarding() {
  const { t } = useI18n()
  const { user } = useUser()
  const queryClient = useQueryClient()

  const {
    mutateAsync: completeOnboarding,
    error,
    ...mutation
  } = useMutation({
    mutationFn: async (payload: CompleteOnboardingRequest['body']) => {
      const { response, data, error } = await CompleteOnboarding({
        params: { path: { workspaceId: user.value!.workspaceId } },
        body: payload,
      })

      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }
      return data
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['get-onboarding-data'] })
      queryClient.invalidateQueries({ queryKey: ['contacts', contactsQueryKey] })
      queryClient.invalidateQueries({ queryKey: ['me'] })
      if (variables?.paymentInfo) {
        toast.success(t('app.keyflowz.onboarding.notification.success'))
      } else {
        toast.success(t('app.keyflowz.onboarding.notification.success_without_payment_information'))
      }
    },
    onError: () => {
      toast.error(t('app.keyflowz.onboarding.notification.error'))
    },
  })

  return { completeOnboarding, error: error as Ref<ServiceError | null>, ...mutation }
}
