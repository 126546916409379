import { varchar } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $ssoProviderId, SSOProviderID } from './_ids'

export const SSOProviderType = {
  SAML: 'SAML2.0',
} as const
export type SSOProviderType = (typeof SSOProviderType)[keyof typeof SSOProviderType]

export const ssoProviders = authSchema.table('sso_provider', {
  id: id<SSOProviderID>($ssoProviderId.prefix),
  name: varchar('name', { enum: [SSOProviderType.SAML] }).notNull(),
  ...timestamps(),
})

export const SSOProvider = createSelectSchema(ssoProviders, { id: SSOProviderID })
export interface SSOProvider extends z.infer<typeof SSOProvider> {}

export const SSOProviderCreate = createInsertSchema(ssoProviders, { id: SSOProviderID }).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface SSOProviderCreate extends z.infer<typeof SSOProviderCreate> {}
