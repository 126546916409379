import { boolean, jsonb, pgEnum, pgTable, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { $costCenterId, CostCenterID } from './_ids'
import { PhysicalAddressBasedOnCountry } from './address-book-properties'
import { WorkspaceID } from './auth/_ids'
import { workspaces } from './auth/workspace'

export const deliveryMethodEnum = pgEnum('delivery_method_types', ['LETTER', 'NOLAS'])

export const DeliveryMethod = z.enum(deliveryMethodEnum.enumValues)
export type DeliveryMethod = z.infer<typeof DeliveryMethod>

export const PaymentContactAddress = z.object({
  address: PhysicalAddressBasedOnCountry,
})

export type PaymentContactAddress = z.infer<typeof PaymentContactAddress>

export const PaymentContactData = PaymentContactAddress.extend({
  name1: z.string(),
  name2: z.string().optional(),
  name3: z.string().optional(),
})
export type PaymentContactData = z.infer<typeof PaymentContactData>

export const costCenter = pgTable('cost_center', {
  id: id<CostCenterID>($costCenterId.prefix),

  name: text('name').notNull(),
  internalReferenceNumber: text('internal_reference_number'),
  deliveryMethod: deliveryMethodEnum('delivery_type').default('NOLAS'),

  mandateReferenceNumber: text('mandate_reference_number'),
  iban: text('iban'),
  paymentName: text('payment_name'),
  creditorId: text('creditor_id'),
  sepaAddress: jsonb('sepa_address').$type<PaymentContactAddress>(),

  beneficiaryData: jsonb('beneficiary')
    .$type<PaymentContactData>()
    .notNull()
    .default({
      name1: '',
      address: {
        addressLine: '',
        city: '',
        country: '',
      },
    }),
  invoiceRecipientData: jsonb('invoice_recipient').$type<PaymentContactData>(),
  billingService: jsonb('billing_service').$type<PaymentContactData>(),

  note: text('note'),

  isWorkspaceDefaultCostCenter: boolean('workspace_default_costcenter').default(false),
  workspace: refId<WorkspaceID>('workspace_id')
    .references(() => workspaces.id, { onDelete: 'cascade' })
    .notNull(),
  ...timestamps(),
})

export const CostCenterRecord = createSelectSchema(costCenter, {
  id: CostCenterID,
  name: z.string(),
  workspace: WorkspaceID,
  createdAt: z.date(),
  beneficiaryData: PaymentContactData,
  billingService: PaymentContactData,
  invoiceRecipientData: PaymentContactData,
  sepaAddress: PaymentContactAddress,
  deliveryMethod: DeliveryMethod,
})
export type CostCenterRecord = z.infer<typeof CostCenterRecord>

export const CostCenterRecordCreate = createInsertSchema(costCenter, {
  id: CostCenterID,
  name: z.string().min(3),
  workspace: WorkspaceID,
  createdAt: z.date().default(new Date()),
  beneficiaryData: PaymentContactData,
  billingService: PaymentContactData,
  invoiceRecipientData: PaymentContactData,
  sepaAddress: PaymentContactAddress,
  deliveryMethod: DeliveryMethod,
})
export type CostCenterRecordCreate = z.infer<typeof CostCenterRecordCreate>
