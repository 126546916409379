import { idPrefix } from '@epostbox/shared/database'

// Auxiliary object (enum-like) used in avoiding potential
// duplicates of id prefix from arbitrary strings
const Prefix = {
  document: 'doc',
  signature: 'sign',
  letterhead: 'leth',
  attachment: 'attc',
  documentAssignment: 'assn',
  textTemplate: 'txtt',
  address: 'addr',
  addressAssignment: 'adas',
  delivery: 'del',
  documentDelivery: 'ddel',
  handshake: 'hsk',
  addressAssignmentSharing: 'asgs',
  workflow: 'wflw',
  workflowRun: 'wrun',
  workflowOutput: 'wout',
  busEvent: 'bsev',
  workflowInterface: 'wint',
  documentLabels: 'dcls',
  costCenter: 'cctr',
} as const

export const $documentId = idPrefix(Prefix.document)
export const DocumentID = $documentId.schema
export type DocumentID = typeof $documentId.schema._type

export const $signatureId = idPrefix(Prefix.signature)
export const SignatureID = $signatureId.schema
export type SignatureID = typeof $signatureId.schema._type

export const $letterheadId = idPrefix(Prefix.letterhead)
export const LetterheadID = $letterheadId.schema
export type LetterheadID = typeof $letterheadId.schema._type

export const $attachmentId = idPrefix(Prefix.attachment)
export const AttachmentID = $attachmentId.schema
export type AttachmentID = typeof $attachmentId.schema._type

export const $documentAssignmentId = idPrefix(Prefix.documentAssignment)
export const DocumentAssignmentID = $documentAssignmentId.schema
export type DocumentAssignmentID = typeof $documentAssignmentId.schema._type

export const $textTemplateId = idPrefix(Prefix.textTemplate)
export const TextTemplateID = $textTemplateId.schema
export type TextTemplateID = typeof $textTemplateId.schema._type

export const $addressId = idPrefix(Prefix.address)
export const AddressID = $addressId.schema
export type AddressID = typeof $addressId.schema._type

export const $addressAssignmentId = idPrefix(Prefix.addressAssignment)
export const AddressAssignmentID = $addressAssignmentId.schema
export type AddressAssignmentID = typeof $addressAssignmentId.schema._type

export const $deliveryId = idPrefix(Prefix.delivery)
export const DeliveryID = $deliveryId.schema
export type DeliveryID = typeof $deliveryId.schema._type

export const $documentDeliveryId = idPrefix(Prefix.documentDelivery)
export const DocumentDeliveryID = $documentDeliveryId.schema
export type DocumentDeliveryID = typeof $documentDeliveryId.schema._type

export const $handshakeId = idPrefix(Prefix.handshake)
export const HandshakeId = $handshakeId.schema
export type HandshakeId = typeof $handshakeId.schema._type

export const $addressAssignmentSharingId = idPrefix(Prefix.addressAssignmentSharing)
export const AddressAssignmentSharingID = $addressAssignmentSharingId.schema
export type AddressAssignmentSharingID = typeof $addressAssignmentSharingId.schema._type

export const $workflowId = idPrefix(Prefix.workflow)
export const WorkflowID = $workflowId.schema
export type WorkflowID = typeof $workflowId.schema._type

export const $workflowRunId = idPrefix(Prefix.workflowRun)
export const WorkflowRunID = $workflowRunId.schema
export type WorkflowRunID = typeof $workflowRunId.schema._type

export const $workflowOutputId = idPrefix(Prefix.workflowOutput)
export const WorkflowOutputID = $workflowOutputId.schema
export type WorkflowOutputID = typeof $workflowOutputId.schema._type

export const $busEventId = idPrefix(Prefix.busEvent)
export const BusEventID = $busEventId.schema
export type BusEventID = typeof $busEventId.schema._type

export const $workflowInterfaceId = idPrefix(Prefix.workflowInterface)
export const WorkflowInterfaceID = $workflowInterfaceId.schema
export type WorkflowInterfaceID = typeof $workflowInterfaceId.schema._type

export const $documentLabelId = idPrefix(Prefix.documentLabels)
export const DocumentLabelID = $documentLabelId.schema
export type DocumentLabelID = typeof $documentLabelId.schema._type

export const $costCenterId = idPrefix(Prefix.costCenter)
export const CostCenterID = $costCenterId.schema
export type CostCenterID = typeof $costCenterId.schema._type
