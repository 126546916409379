<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { type FormContext, useForm } from 'vee-validate'
import { computed, ref, watch } from 'vue'
import { z } from 'zod'

import { Button } from '@epostbox/ui/button'
import { useCountries, germanyRegions } from '@epostbox/ui/composables'
import { Dialog } from '@epostbox/ui/dialog'
import { FormField } from '@epostbox/ui/form'
import { Heading } from '@epostbox/ui/heading'
import { IconButton } from '@epostbox/ui/icon-button'
import { Input } from '@epostbox/ui/input'
import { Paragraph } from '@epostbox/ui/paragraph'
import { RadioGroup, RadioGroupItem } from '@epostbox/ui/radio-group'
import { Select } from '@epostbox/ui/select'
import { Textarea } from '@epostbox/ui/textarea'

import { useCompleteOnboarding } from '@composables/onboarding/use-complete-onboarding'
import { useGetOnboardingData } from '@composables/onboarding/use-get-onboarding'
import { useUser } from '@composables/use-user'

const { user } = useUser()

const isDialogOpen = ref(false)
const id = 'onboarding'

const step = ref(1)
const stepsLength = 3

const emit = defineEmits<{
  focus: []
  blur: []
  close: []
}>()

const { countries } = useCountries()
const { completeOnboarding, isPending } = useCompleteOnboarding()
const { onboardingData } = useGetOnboardingData()

const schemaStep1 = z
  .object({
    name: z.string().trim().nullish(),
    organization_name: z.string().trim().nullish(),
    country: z.string().trim().nullish(),
    region: z.string().trim().nullish(),
    street: z.string().trim().nullish(),
    houseNumber: z.string().trim().nullish(),
    zipCode: z.string().trim().nullish(),
    city: z.string().trim().nullish(),
    addressLine: z.string().trim().nullish(),
  })
  .superRefine((data, ctx) => {
    if (!data.name) {
      ctx.addIssue({
        code: 'custom',
        message: 'common.error.required',
        path: ['name'],
      })
    }
    if (!data.organization_name) {
      ctx.addIssue({
        code: 'custom',
        message: 'common.error.required',
        path: ['organization_name'],
      })
    }
    if (data.country) {
      if (data.country === 'DE') {
        if (!data.region) {
          ctx.addIssue({
            code: 'custom',
            message: 'common.error.required',
            path: ['region'],
          })
        }
        if (!data.street) {
          ctx.addIssue({
            code: 'custom',
            message: 'common.error.required',
            path: ['street'],
          })
        }
        if (!data.houseNumber) {
          ctx.addIssue({
            code: 'custom',
            message: 'common.error.required',
            path: ['houseNumber'],
          })
        }
        if (!data.zipCode) {
          ctx.addIssue({
            code: 'custom',
            message: 'common.error.required',
            path: ['zipCode'],
          })
        }
        if (!data.city) {
          ctx.addIssue({
            code: 'custom',
            message: 'common.error.required',
            path: ['city'],
          })
        }
      } else {
        if (!data.addressLine) {
          ctx.addIssue({
            code: 'custom',
            message: 'common.error.required',
            path: ['addressLine'],
          })
        }
      }
    } else {
      ctx.addIssue({
        code: 'custom',
        message: 'common.error.required',
        path: ['country'],
      })
    }
  })

const schemaStep2 = z
  .object({
    eInvoiceChannel: z.enum(['nolas', 'postal', 'email']).nullish(),
  })
  .superRefine((data, ctx) => {
    if (!data.eInvoiceChannel) {
      ctx.addIssue({
        code: 'custom',
        message: 'common.error.required',
        path: ['eInvoiceChannel'],
      })
    }
  })
const schemaStep3 = z.object({
  paymentName: z.string().trim().nullish(),
  iban: z.string().trim().nullish(),
})

const channels = [
  {
    id: 'nolas',
    name: 'nolas',
  },
  {
    id: 'postal',
    name: 'post',
  },
  {
    id: 'email',
    name: 'email',
  },
]

const stepsSchema = [schemaStep1, schemaStep2, schemaStep3]

const currentStepSchema = computed(() => {
  return toTypedSchema(stepsSchema[step.value - 1])
})

const form = useForm<z.infer<typeof schemaStep1> & z.infer<typeof schemaStep2> & z.infer<typeof schemaStep3>>({
  validationSchema: currentStepSchema,
  keepValuesOnUnmount: true,
  initialValues: {
    name: undefined,
    organization_name: undefined,
    country: 'DE',
    region: undefined,
    street: undefined,
    houseNumber: undefined,
    zipCode: undefined,
    city: undefined,
    addressLine: undefined,
    eInvoiceChannel: undefined,
    paymentName: undefined,
    iban: undefined,
  },
})

watch(
  () => onboardingData,
  () => {
    const addressValue = onboardingData.value?.contactData
    form.setValues(
      {
        name: onboardingData.value?.name,
        ...(user.value?.onboardingStatus !== 'required' && {
          organization_name: onboardingData.value?.organisationName,
        }),
        country: onboardingData.value?.contactData?.country || 'DE',
        ...(addressValue && 'city' in addressValue
          ? {
              region: addressValue?.region,
              street: addressValue?.street,
              houseNumber: addressValue?.houseNumber,
              zipCode: addressValue?.zipCode,
              city: addressValue?.city,
            }
          : {
              addressLine: addressValue?.addressLine,
            }),
        eInvoiceChannel: onboardingData.value?.channelPreference || undefined,
        paymentName: onboardingData.value?.paymentInfo?.paymentName,
        iban: onboardingData.value?.paymentInfo?.iban,
      },
      false
    )
  },
  { immediate: true, deep: true }
)

const onSubmit = async (skipPayment: boolean = false) => {
  await completeOnboarding(
    {
      name: form.values.name as string,
      organisationName: form.values.organization_name as string,
      contactData: {
        country: form.values.country as string,
        ...(form.values.country === 'DE'
          ? {
              region: form.values.region ?? '',
              city: form.values.city ?? '',
              zipCode: form.values.zipCode ?? '',
              street: form.values.street ?? '',
              houseNumber: form.values.houseNumber ?? '',
            }
          : {
              addressLine: form.values.addressLine ?? '',
            }),
      },
      channelPreference: form.values.eInvoiceChannel ?? undefined,

      ...(skipPayment
        ? {}
        : {
            paymentInfo: {
              paymentName: form.values.paymentName ?? null,
              iban: form.values.iban ?? null,
            },
          }),
    },
    {
      onSuccess: () => {
        emit('close')
        step.value = 1
      },
    }
  )
}

const nextStep = async () => {
  const isValid = await form?.validate()
  if (isValid.valid && step.value < stepsLength) {
    step.value += 1
  }
}

const openCountries = ref(false)
const openRegions = ref(false)

const onStepBack = () => {
  step.value -= 1
}

const resetFields = (form: FormContext) => {
  form.resetForm({
    values: {
      ...form.values,
      country: form.values.country,
      region: undefined,
      street: undefined,
      houseNumber: undefined,
      zipCode: undefined,
      city: undefined,
      addressLine: undefined,
    },
  })
}
</script>

<template>
  <Dialog
    :id="id"
    v-model:open="isDialogOpen"
    :class="'!h-[800px] !w-[1124px] outline-none'"
    hide-close-button
    content-class="w-full"
  >
    <template #trigger>
      <slot />
    </template>
    <template #header-icon>
      <INolasSave01 class="h-10 w-10 min-w-10" />
    </template>

    <div class="flex h-full max-h-[calc(100vh-100px)] px-8 py-10">
      <img src="/onboarding-image.png" alt="onboarding modal" class="aspect-[3/4] h-full !w-[556px] pr-5" />
      <div class="flex w-full flex-col pl-5">
        <IconButton
          v-if="step > 1"
          size="md"
          variant="secondary"
          class="!h-8 !w-8 !min-w-8 !rounded-full"
          :data-e2e="`${id}-step-back`"
          @click="onStepBack"
        >
          <INolasArrowLeft class="h-5 w-5 min-w-5" />
        </IconButton>

        <Heading as="h2" size="2xl" :class="['flex flex-col font-medium', step === 1 ? 'mb-8 mt-14' : 'mb-2 mt-6']">
          {{ $t(`app.keyflowz.onboarding.title.step_${step}`) }}
          <span v-if="step === stepsLength" class="capitalize">{{ `(${$t('common.optional')})` }}</span>
        </Heading>

        <form class="flex h-full w-full flex-1 flex-col justify-between gap-4" :data-e2e="`${id}-form`" @submit.prevent>
          <div v-if="step === 1" class="h-full max-h-[calc(100vh-438px)] w-full overflow-y-auto">
            <FormField v-slot="{ componentField }" :form="form" name="name" class="mb-0">
              <Input
                v-bind="componentField"
                :label="$t(`app.keyflowz.onboarding.fields.name`)"
                :data-e2e="`${id}-form-input-name`"
                required
              />
            </FormField>
            <FormField v-slot="{ componentField }" :form="form" name="organization_name" class="mb-0">
              <Input
                v-bind="componentField"
                :label="$t(`app.keyflowz.onboarding.fields.organization_name`)"
                :data-e2e="`${id}-form-input-organization_name`"
                required
              />
            </FormField>
            <Heading as="h2" size="md" class="font-medium] mb-4 mt-8">
              {{ $t(`app.keyflowz.onboarding.text.org_address`) }}
            </Heading>
            <FormField v-slot="{ componentField }" :form="form" name="country" class="col-span-full">
              <Select
                v-bind="componentField"
                v-model:open="openCountries"
                :label="$t('common.fields.country.label')"
                :items="countries"
                translatable
                required
                :data-e2e="`${id}-select-country`"
                @update:model-value="resetFields(form)"
              />
            </FormField>
            <div v-if="form.values.country === 'DE'">
              <FormField v-slot="{ componentField }" :form="form" name="region" class="col-span-full">
                <Select
                  v-bind="componentField"
                  v-model:open="openRegions"
                  :label="$t('common.fields.region.label')"
                  :items="germanyRegions"
                  translatable
                  required
                  :data-e2e="`${id}-select-region`"
                />
              </FormField>
              <div class="flex flex-row gap-4">
                <FormField v-slot="{ componentField }" :form="form" name="street" class="w-3/5">
                  <Input
                    v-bind="componentField"
                    :label="$t('common.fields.street.label')"
                    required
                    :data-e2e="`${id}-input-street`"
                  />
                </FormField>
                <FormField v-slot="{ componentField }" :form="form" name="houseNumber" class="w-2/5">
                  <Input
                    v-bind="componentField"
                    required
                    :label="$t('common.fields.street_number.label')"
                    :data-e2e="`${id}-input-number`"
                  />
                </FormField>
              </div>

              <div class="flex flex-row gap-4">
                <FormField v-slot="{ componentField }" :form="form" name="zipCode" class="w-2/5">
                  <Input
                    v-bind="componentField"
                    required
                    :label="$t('common.fields.zip.label')"
                    :data-e2e="`${id}-input-zip`"
                  />
                </FormField>
                <FormField v-slot="{ componentField }" :form="form" name="city" class="w-3/5">
                  <Input
                    v-bind="componentField"
                    required
                    :label="$t('common.fields.city.label')"
                    :data-e2e="`${id}-input-city`"
                  />
                </FormField>
              </div>
            </div>
            <FormField v-else v-slot="{ componentField }" :form="form" name="addressLine">
              <Textarea
                class="!w-full overflow-hidden"
                v-bind="componentField"
                :resizable="false"
                required
                :label="$t('common.fields.addressLine.label')"
                :data-e2e="`${id}-input-addressLine`"
              />
            </FormField>
          </div>
          <div v-else-if="step === 2" class="h-full max-h-[calc(100vh-438px)] w-full overflow-y-auto">
            <Paragraph size="base" weight="medium" class="mb-8 text-neutral-850">
              {{ $t(`app.keyflowz.onboarding.description.step_${step}`) }}
            </Paragraph>
            <FormField v-slot="{ componentField }" :form="form" name="eInvoiceChannel" class="mb-0">
              <RadioGroup v-bind="componentField" class="flex flex-col gap-4">
                <div
                  v-for="channel in channels"
                  :key="`channel-${channel.id}`"
                  class="flex cursor-pointer items-center gap-2.5 bg-white"
                  :data-e2e="`${id}-radio-eInvoiceChannel`"
                >
                  <RadioGroupItem
                    :id="channel.id"
                    :value="channel.id"
                    :data-e2e="`${id}-radio-eInvoiceChannel-${channel.id}`"
                  />
                  <label :for="channel.id" class="w-full">
                    <Paragraph size="sm" class="font-medium text-gray-1000">{{ channel.name }}</Paragraph>
                  </label>
                </div>
              </RadioGroup>
            </FormField>
          </div>
          <div v-else-if="step === 3" class="h-full max-h-[calc(100vh-438px)] w-full overflow-y-auto">
            <Paragraph size="base" weight="medium" class="mb-8 text-neutral-850">
              {{ $t(`app.keyflowz.onboarding.description.step_${step}`) }}
            </Paragraph>
            <FormField v-slot="{ componentField }" :form="form" name="paymentName" class="mb-0">
              <Input
                v-bind="componentField"
                :label="$t(`app.keyflowz.onboarding.fields.account_holder`)"
                :data-e2e="`${id}-form-input-payment-account-holder`"
              />
            </FormField>
            <FormField v-slot="{ componentField }" :form="form" name="iban" class="mb-0">
              <Input
                v-bind="componentField"
                :label="$t(`app.keyflowz.onboarding.fields.iban`)"
                :data-e2e="`${id}-form-input-payment-iban`"
              />
            </FormField>
          </div>
          <div class="sticky bottom-0 flex w-full items-center gap-2 bg-white pt-10">
            <div class="flex w-full gap-2">
              <div
                v-for="stepCircle in stepsLength"
                :key="`overlay-${stepCircle}`"
                :class="[
                  'h-2.5 w-2.5 rounded-full transition-all',
                  step === stepCircle ? 'bg-brand-550' : 'bg-gray-warm-50',
                ]"
              />
            </div>
            <div class="flex gap-3">
              <Button
                v-if="step === stepsLength"
                type="button"
                variant="secondary"
                size="lg"
                :disabled="isPending"
                class="w-28"
                :data-e2e="`${id}-skip`"
                @click="onSubmit(true)"
              >
                {{ $t('common.skip') }}
              </Button>
              <Button
                type="button"
                variant="primary"
                size="lg"
                class="w-28"
                :disabled="isPending || (step === stepsLength && (!form.values.paymentName || !form.values.iban))"
                :data-e2e="step === stepsLength ? `${id}-submit` : `${id}-next`"
                @click="step === stepsLength ? onSubmit() : nextStep()"
              >
                {{ step === stepsLength ? $t('common.done') : $t('common.next') }}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Dialog>
</template>
