import { cva, type VariantProps } from 'class-variance-authority'
import type { SelectRootProps, SelectTriggerProps } from 'radix-vue'

import { type ErrorMessage } from '../form'

export { default as PhoneSelect } from './phone-select.vue'
export { default as SelectContent } from './select-content.vue'
export { default as SelectGroup } from './select-group.vue'
export { default as SelectItemText } from './select-item-text.vue'
export { default as SelectItem } from './select-item.vue'
export { default as SelectLabel } from './select-label.vue'
export { default as SelectSeparator } from './select-separator.vue'
export { default as SelectTrigger } from './select-trigger.vue'
export { default as SelectValue } from './select-value.vue'
export { default as Select } from './select.vue'
export interface Item {
  label: string
  value: string
  e2e?: string
  unicode?: string
  dial_code?: string
  icon?: unknown
  type?: string
}

export interface ItemsByGroup {
  title: string
  items: Item[]
}

export const getItem = (items: Item[], value: string | undefined) => {
  if (!value) return
  return items.find(item => item.value === value)
}

export const selectVariants = cva(
  'flex items-center outline-none text-sm font-medium text-gray-1000 leading-5 overflow-hidden truncate group-hover:bg-gray-warm-350 disabled:text-gray-850',
  {
    variants: {
      color: {
        beige: 'bg-gray-warm-50',
        white: 'bg-white',
      },
      size: {
        sm: 'h-8 w-24 px-1',
        md: 'h-13 w-full px-4',
      },
    },
    defaultVariants: {
      color: 'beige',
      size: 'md',
    },
  }
)

export const selectWrapperVariants = cva(
  'group border overflow-hidden border-2 border-transparent focus-within:border-primary-500 rounded-lg hover:bg-gray-warm-350 group-hover:border-gray-warm-350',
  {
    variants: {
      color: {
        beige: 'bg-gray-warm-50',
        white: 'bg-white',
      },
      size: {
        sm: 'h-8 w-24',
        md: 'h-13 w-full',
      },
    },
    defaultVariants: {
      color: 'beige',
      size: 'md',
    },
  }
)

type Variants = VariantProps<typeof selectVariants>

export interface SelectProps extends SelectRootProps {
  color?: Variants['color']
  size?: Variants['size']
  placeholder?: string
  label?: string
  items: Item[] | ItemsByGroup[]
  error?: ErrorMessage
  translatable?: boolean
  contentClasses?: string
  triggerClasses?: string
  wrapperClasses?: string
  dataE2e?: string
  required?: boolean
}

export interface CustomSelectTriggerProps extends SelectTriggerProps {
  name: string
  label?: string
  class?: string
  invalid?: boolean
  open: boolean
  error?: ErrorMessage
  value?: string
  contentClasses?: string
  triggerClasses?: string
  wrapperClasses?: string
  dataE2e?: string
  color?: Variants['color']
  size?: Variants['size']
  required?: boolean
  hideErrorIcon?: boolean
}
