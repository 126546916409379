import { InferSelectModel, relations } from 'drizzle-orm'
import { jsonb, pgEnum, primaryKey, uniqueIndex } from 'drizzle-orm/pg-core'
import { createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { pkPart, refId } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $roleId, RoleID, WorkspaceID } from './_ids'
import { userRoles } from './user-role'

export const roleNameEnum = pgEnum('role_name_enum', ['Workspace Admin', 'Admin', 'User'])
export const RoleName = z.enum(['Workspace Admin', 'Admin', 'User'])
export type RoleName = (typeof roleNameEnum.enumValues)[number]

export const RoleActions = z.enum(['admins:create', 'folders:share'])
export type RoleActions = z.infer<typeof RoleActions>

export const RolePermision = z.object({
  effect: z.enum(['allow', 'deny']),
  action: z.literal('*').or(z.array(RoleActions)),
})
export type RolePermision = z.infer<typeof RolePermision>

export const roles = authSchema.table(
  'roles',
  {
    id: pkPart<RoleID>($roleId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),
    name: roleNameEnum('name').notNull().default('User'),
    permissions: jsonb('permissions').$type<RolePermision>().notNull(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
    unique_workspace_name_idx: uniqueIndex('unique_workspace_name_idx').on(table.name, table.workspaceId),
  })
)

export const RoleRecord = createSelectSchema(roles, {
  id: RoleID,
  workspaceId: WorkspaceID,
  permissions: RolePermision,
})
export type RoleRecord = InferSelectModel<typeof roles>

export const roleRelations = relations(roles, ({ one }) => ({
  userRoles: one(userRoles),
}))
