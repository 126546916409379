import { index, integer, jsonb, pgTable, primaryKey, text, varchar } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { pkPart, refId, timestamps } from '@epostbox/shared/database'

import { $attachmentId, AttachmentID } from './_ids'
import { WorkspaceID } from './auth/_ids'
import { Thumbnails } from './documents'

export const attachments = pgTable(
  'attachments',
  {
    id: pkPart<AttachmentID>($attachmentId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),

    name: text('name').notNull(),
    key: text('key').notNull(),
    fileName: text('file_name').notNull(),
    size: integer('size').notNull(),
    mimeType: text('mime_type').notNull(),
    pages: integer('pages').notNull(),
    status: varchar('status', { enum: ['pending', 'uploaded'] })
      .default('pending')
      .notNull(),
    thumbnails: jsonb('thumbnails').$type<Thumbnails>().default([]),
    ...timestamps(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
    keyIndex: index('attachments_key_index').on(table.key),
  })
)

const AttachmentSchema = {
  id: AttachmentID,
  workspaceId: WorkspaceID,
  thumbnails: Thumbnails,
}

export const AttachmentRecord = createSelectSchema(attachments, AttachmentSchema)
export type AttachmentRecord = z.infer<typeof AttachmentRecord>

export const AttachmentRecordCreate = createInsertSchema(attachments, AttachmentSchema).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})
export type AttachmentRecordCreate = z.infer<typeof AttachmentRecordCreate>
