import { jsonb, text, boolean } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $customerDataId, CustomerDataID } from './_ids'

export const CustomerData = z.object({
  country: z.string(),
  city: z.string(),
  postalCode: z.string(),
  name: z.string(),
  address_line_1: z.string(),
  address_line_2: z.string().optional(),
  phone_number: z.string().optional(),
  email: z.string(),
  isUsed: z.boolean().optional(),
})
export type CustomerData = z.infer<typeof CustomerData>

/** We store refresh tokens and whether they are invalid. A refresh token should be valid for 15 minutes. */
export const customerData = authSchema.table('customer_data', {
  id: id<CustomerDataID>($customerDataId.prefix),
  token: text('token').unique().notNull(),
  data: jsonb('data').$type<CustomerData>().notNull(),
  isUsed: boolean('is_used').default(false),
  ...timestamps(),
})

export const CustomerDataRecord = createSelectSchema(customerData, {
  id: CustomerDataID,
  data: CustomerData,
})

export interface CustomerDataRecord extends z.infer<typeof CustomerDataRecord> {}

export const CustomerDataRecordCreate = createInsertSchema(customerData, {
  id: CustomerDataID,
  data: CustomerData,
}).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})

export interface CustomerDataRecordCreate extends z.infer<typeof CustomerDataRecordCreate> {}
