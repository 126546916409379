import { createQueryKeys } from '@lukemorales/query-key-factory'
import { GetOnboarding } from '@services/keyflowz-api'
import { useQuery } from '@tanstack/vue-query'
import { Ref, ref } from 'vue'

import { ServiceError } from '@epostbox/shared/errors'

import { useUser } from '@composables/use-user'

export const GetOnboardingData = createQueryKeys('get-onboarding-data')
const isOnboardingModalOpen = ref(false)

export function useGetOnboardingData() {
  const { user } = useUser()

  const {
    data: onboardingData,
    error,
    ...queryRest
  } = useQuery({
    queryKey: GetOnboardingData._def,
    retry: 2,
    enabled: () => !!user.value && !!isOnboardingModalOpen.value,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    queryFn: async () => {
      const { response, data, error } = await GetOnboarding({
        params: { path: { workspaceId: user.value!.workspaceId } },
      })

      if (!response.ok || !data) {
        throw ServiceError.fromResponse(error)
      }
      return data
    },
  })

  return {
    onboardingData,
    isOnboardingModalOpen,
    error: error as Ref<ServiceError | null>,
    ...queryRest,
  }
}
8
