import { index } from 'drizzle-orm/pg-core'
import { pgTable, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { $textTemplateId, TextTemplateID } from './_ids'
import { UserID, WorkspaceID } from './auth/_ids'

export const textTemplates = pgTable(
  'text_template',
  {
    id: id<TextTemplateID>($textTemplateId.prefix),
    name: text('name').notNull(),
    key: text('key').notNull().default(''),
    htmlContent: text('html').notNull(),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),
    createdBy: refId<UserID>('created_by').notNull(),
    ...timestamps(),
  },
  table => ({
    workspaceid_idx: index('text_templates_workspaceid_idx').on(table.workspaceId),
  })
)

const TextTemplateSchema = {
  id: TextTemplateID,
  workspaceId: WorkspaceID,
  createdBy: UserID,
}

export const TextTemplateRecord = createSelectSchema(textTemplates, TextTemplateSchema)
export type TextTemplateRecord = z.infer<typeof TextTemplateRecord>

export const TextTemplateRecordCreate = createInsertSchema(textTemplates, TextTemplateSchema).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})
export type TextTemplateRecordCreate = z.infer<typeof TextTemplateRecordCreate>
