import { cva, type VariantProps } from 'class-variance-authority';



import { type ErrorMessage } from '../form';


export { default as Textarea } from './textarea.vue'

export const textareaVariants = cva(
  'outline-none text-sm w-full min-h-[170px] font-medium text-gray-1000 leading-5 overflow-y-auto rounded-lg group-hover:bg-gray-warm-350 disabled:text-gray-850 pl-4 pr-2 pb-5 ',
  {
    variants: {
      color: {
        beige: 'bg-gray-warm-50',
        white: 'bg-white',
      },
    },
    defaultVariants: {
      color: 'beige',
    },
  }
)
export const textareaLabelVariants = cva(
  'absolute left-4 top-5 text-xs origin-[0] -translate-y-3 transform font-medium text-gray-625 duration-300 peer-placeholder-shown:translate-y-0 peer-focus:text-xs rtl:peer-focus:left-auto  peer-disabled:text-gray-warm-850'
)

export const textareaWrapperVariants = cva(
  'group border overflow-hidden border-2 h-full border-transparent focus-within:border-primary-500  px-0 pt-7   w-full rounded-lg hover:bg-gray-warm-350',
  {
    variants: {
      color: {
        beige: 'bg-gray-warm-50',
        white: 'bg-white',
      },
    },
    defaultVariants: {
      color: 'beige',
    },
  }
)

type Variants = VariantProps<typeof textareaVariants>

export interface TextAreaProps {
  defaultValue?: string | number
  modelValue?: string | number
  disabled?: boolean
  error?: ErrorMessage
  name: string
  color?: Variants['color']
  label?: string
  required?: boolean
  readonly?: boolean
  inputClass?: string
}