import { jsonb, pgTable, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { $documentLabelId, DocumentLabelID } from './_ids'
import { WorkspaceID } from './auth/_ids'

export const DocumentClass = z.object({
  name: z.string(),
  description: z.string(),
  subclasses: z.array(
    z.object({
      name: z.string(),
      description: z.string(),
      entities: z.array(
        z.object({
          name: z.string(),
          description: z.string(),
          important: z.boolean().optional(),
        })
      ),
    })
  ),
})
export type DocumentClass = z.infer<typeof DocumentClass>

export const documentLabels = pgTable('document_label', {
  id: id<DocumentLabelID>($documentLabelId.prefix),
  workspaceId: refId<WorkspaceID>('workspace_id'),

  name: text('name'),
  data: jsonb('data').notNull().$type<DocumentClass[]>(),

  ...timestamps(),
})

export const DocumentLabelRecord = createSelectSchema(documentLabels, {
  id: DocumentLabelID,
  workspaceId: WorkspaceID,
})
export type DocumentLabelRecord = z.infer<typeof DocumentLabelRecord>

export const DocumentLabelRecordCreate = createInsertSchema(documentLabels, {
  id: DocumentLabelID,
  workspaceId: WorkspaceID,
})
export type DocumentLabelRecordCreate = z.infer<typeof DocumentLabelRecordCreate>
