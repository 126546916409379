import { relations } from 'drizzle-orm'
import { boolean, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $refreshTokenId, RefreshTokenID, SessionID, UserID } from './_ids'
import { sessions } from './session'
import { users } from './user'

/** We store refresh tokens and whether they are invalid. A refresh token should be valid for 15 minutes. */
export const refreshTokens = authSchema.table('refresh_token', {
  id: id<RefreshTokenID>($refreshTokenId.prefix),
  token: text('token').unique().notNull(),
  parent: text('parent'),
  sessionId: refId<SessionID>('session_id')
    .references(() => sessions.id, { onDelete: 'cascade' })
    .notNull(),
  userId: refId<UserID>('user_id')
    .references(() => users.id, { onDelete: 'cascade' })
    .notNull(),
  revoked: boolean('revoked').default(false),

  ...timestamps(),
})

export const RefreshTokenRecord = createSelectSchema(refreshTokens, {
  id: RefreshTokenID,
  sessionId: SessionID,
  userId: UserID,
})
export interface RefreshTokenRecord extends z.infer<typeof RefreshTokenRecord> {}

export const RefreshTokenRecordCreate = createInsertSchema(refreshTokens, {
  sessionId: SessionID,
  userId: UserID,
}).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface RefreshTokenRecordCreate extends z.infer<typeof RefreshTokenRecordCreate> {}

export const refreshTokenRelations = relations(refreshTokens, ({ one }) => ({
  user: one(users, {
    fields: [refreshTokens.userId],
    references: [users.id],
  }),
}))
