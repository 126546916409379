import { InferInsertModel, InferSelectModel, relations } from 'drizzle-orm'
import { jsonb, pgTable, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { pkPart, refId, timestamps } from '@epostbox/shared/database'

import { $workflowRunId, DocumentID, WorkflowID, WorkflowRunID } from './_ids'
import { documents } from './documents'
import { StateMachine, workflow } from './workflow'
import { workflowOutput } from './workflow-output'

export const workflowRun = pgTable('workflow_run', {
  id: pkPart<WorkflowRunID>($workflowRunId.prefix),
  workflowId: refId<WorkflowID>('workflow_id')
    .references(() => workflow.id, { onDelete: 'cascade' })
    .notNull(),
  documentId: refId<DocumentID>('document_id')
    .references(() => documents.id, { onDelete: 'cascade' })
    .notNull(),
  currentState: text('current_state').notNull(), // an ulid = id of the current state
  stateMachine: jsonb('state_machine').$type<StateMachine>(),
  ...timestamps(),
})

const WorkflowRunSchema = {
  id: WorkflowRunID,
  workflowId: WorkflowID,
  documentId: DocumentID,
}
export const WorkflowRunRecord = createSelectSchema(workflowRun, WorkflowRunSchema)
export type WorkflowRunRecord = InferSelectModel<typeof workflowRun>

export const WorkflowRunRecordCreate = createInsertSchema(workflowRun, WorkflowRunSchema)
export type WorkflowRunRecordCreate = InferInsertModel<typeof workflowRun>

export const workflowRunRelations = relations(workflowRun, ({ one, many }) => ({
  workflow: one(workflow, {
    fields: [workflowRun.workflowId],
    references: [workflow.id],
  }),
  output: many(workflowOutput),
}))
