import { pgEnum, text, timestamp } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $userIdentityId, UserIdentityId } from './_ids'

export const userIdentityStatus = pgEnum('identity_status', ['pending', 'identified'])
export const UserIdentityStatus = z.enum(userIdentityStatus.enumValues)
export type UserIdentityStatus = z.infer<typeof UserIdentityStatus>

export const userIdentityType = pgEnum('identity_type', ['2_letter', 'id_card', 'by_organisation', 'by_signup'])
export const UserIdentityType = z.enum(userIdentityType.enumValues)
export type UserIdentityType = z.infer<typeof UserIdentityType>

export const userIdentity = authSchema.table('user_identity', {
  id: id<UserIdentityId>($userIdentityId.prefix),

  status: userIdentityStatus('status').default('pending'),
  type: userIdentityType('type').notNull(),

  hash: text('hash'),

  identifiedAt: timestamp('identified_at'),
  ...timestamps(),
})

const UserIdentitySchema = {
  id: UserIdentityId,
  status: z.enum(userIdentityStatus.enumValues),
  type: z.enum(userIdentityType.enumValues),
}

export const UserIdentityRecord = createSelectSchema(userIdentity, UserIdentitySchema)
export interface UserIdentityRecord extends z.infer<typeof UserIdentityRecord> {}

export const UserIdentityRecordCreate = createInsertSchema(userIdentity, UserIdentitySchema).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface UserIdentityRecordCreate extends z.infer<typeof UserIdentityRecordCreate> {}
