import { text, timestamp } from 'drizzle-orm/pg-core'
import { ulid } from 'ulid'
import * as z from 'zod'

export const idPrefix = <T extends string>(prefix: T) => ({
  prefix: prefix as T,
  schema: z.string().startsWith(`${prefix}_`).brand(prefix),
})

export const ulidId = (prefix: string) => `${prefix}_${ulid().toLowerCase()}`

/** @param {string} prefix prefer using max. 4 characters if possible */
export const id = <T = string>(prefix: string) =>
  text('id')
    .$defaultFn(() => ulidId(prefix))
    .primaryKey()
    .$type<T>()

export const pkPart = <T = string>(prefix: string) =>
  text('id')
    .$defaultFn(() => ulidId(prefix))
    .unique()
    .notNull()
    .$type<T>()

/** shorthand for referencing a foreign id */
export const refId = <T = string>(name: string) => text(name).$type<T>()

export const timestamps = () => ({
  createdAt: timestamp('created_at').defaultNow().notNull(),
  updatedAt: timestamp('updated_at').defaultNow(),
  deletedAt: timestamp('deleted_at'),
})
