import { index, text } from 'drizzle-orm/pg-core'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $flowStateId, FlowStateID, SessionID, UserID } from './_ids'
import { sessions } from './session'
import { users } from './user'

export const methodsEnum = authSchema.enum('code_challenge_methods', ['plain', 'S256'])

export const flowStates = authSchema.table(
  'flow_state',
  {
    id: id<FlowStateID>($flowStateId.prefix),
    userId: refId<UserID>('user_id')
      .references(() => users.id, { onDelete: 'cascade' })
      .notNull(),

    authCode: text('auth_code').notNull(),
    codeChallengeMethod: methodsEnum('code_challenge_method').notNull(),
    codeChallenge: text('code_challenge').notNull(),

    sessionId: refId<SessionID>('session_id').references(() => sessions.id, { onDelete: 'cascade' }),
    ...timestamps(),
  },
  t => ({
    authCodeIdx: index('auth_code_index').on(t.authCode),
  })
)
export type FlowStateRecord = typeof flowStates.$inferSelect
export type FlowStateRecordCreate = typeof flowStates.$inferInsert
