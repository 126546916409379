import { InferSelectModel, relations } from 'drizzle-orm'
import { createSelectSchema } from 'drizzle-zod'

import { refId } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { RoleID, UserID } from './_ids'
import { roles } from './role'
import { users } from './user'

export const userRoles = authSchema.table('user_roles', {
  userId: refId<UserID>('user_id')
    .primaryKey()
    .notNull()
    .references(() => users.id, { onDelete: 'cascade' }),
  roleId: refId<RoleID>('role_id')
    .references(() => roles.id, { onDelete: 'cascade' })
    .notNull(),
})

export const UserRoleRecord = createSelectSchema(userRoles, {
  userId: UserID,
  roleId: RoleID,
})
export type UserRoleRecord = InferSelectModel<typeof userRoles>

export const userRolesRelations = relations(userRoles, ({ one }) => ({
  user: one(users, {
    fields: [userRoles.userId],
    references: [users.id],
  }),
  role: one(roles, {
    fields: [userRoles.roleId],
    references: [roles.id],
  }),
}))
