import { relations } from 'drizzle-orm'
import { varchar, timestamp, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $workspaceMemberId, UserID, WorkspaceID, WorkspaceMemberID } from './_ids'
import { users } from './user'
import { workspaces } from './workspace'

export const workspaceMembers = authSchema.table('workspace_member', {
  id: id<WorkspaceMemberID>($workspaceMemberId.prefix),
  workspaceId: refId<WorkspaceID>('workspace_id')
    .references(() => workspaces.id, { onDelete: 'cascade' })
    .notNull(),
  userId: refId<UserID>('user_id')
    .references(() => users.id, { onDelete: 'cascade' })
    .notNull(),
  addMethod: varchar('add_method', { enum: ['scim', 'invitation'] }),
  scimId: text('scim_id'),
  status: varchar('status', { enum: ['joined', 'invited'] }),
  invitedAt: timestamp('invited_at'),
  invitedBy: refId<UserID>('invited_by').references(() => users.id, { onDelete: 'set null' }),
  ...timestamps(),
})

export const WorkspaceMemberRecord = createSelectSchema(workspaceMembers, {
  id: WorkspaceMemberID,
  workspaceId: WorkspaceID,
  userId: UserID,
  invitedBy: UserID,
})
export interface WorkspaceMemberRecord extends z.infer<typeof WorkspaceMemberRecord> {}

export const WorkspaceMemberRecordCreate = createInsertSchema(workspaceMembers, {
  workspaceId: WorkspaceID,
  userId: UserID,
  invitedBy: UserID,
}).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface WorkspaceMemberRecordCreate extends z.infer<typeof WorkspaceMemberRecordCreate> {}

export const workspaceMemberRelations = relations(workspaceMembers, ({ one }) => ({
  user: one(users, { fields: [workspaceMembers.userId], references: [users.id], relationName: 'memberOf' }),
  invitedByUser: one(users, {
    fields: [workspaceMembers.invitedBy],
    references: [users.id],
    relationName: 'inviterOf',
  }),
  workspace: one(workspaces, { fields: [workspaceMembers.workspaceId], references: [workspaces.id] }),
}))
