import { relations } from 'drizzle-orm'
import { text, varchar } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $profileId, ProfileID, UserID } from './_ids'
import { users } from './user'

export const profiles = authSchema.table('profile', {
  id: id<ProfileID>($profileId.prefix),
  userId: refId<UserID>('user_id')
    .references(() => users.id, { onDelete: 'cascade' })
    .notNull(),
  name: text('name').notNull(),
  profilePicture: text('profile_picture'),
  jobTitle: text('job_title'),
  phoneNumber: text('phone_number'),
  country: text('country'),
  city: text('city'),
  addressLine1: text('address_line_1'),
  addressLine2: text('address_line_2'),
  postalCode: text('postal_code'),
  mailboxPreference: varchar('mailbox_preference', {
    enum: ['digital', 'postal'],
  })
    .default('postal')
    .notNull(),

  ...timestamps(),
})

export const ProfileRecord = createSelectSchema(profiles, { id: ProfileID, userId: UserID })
export interface ProfileRecord extends z.infer<typeof ProfileRecord> {}

export const ProfileRecordCreate = createInsertSchema(profiles, { userId: UserID }).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface ProfileRecordCreate extends z.infer<typeof ProfileRecordCreate> {}

export const profileRelations = relations(profiles, ({ one }) => ({
  user: one(users, {
    fields: [profiles.userId],
    references: [users.id],
  }),
}))
