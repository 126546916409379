import { z } from '@hono/zod-openapi'
import { relations } from 'drizzle-orm'
import { index, text, timestamp } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { id, lower, refId, timestamps } from '@epostbox/shared/database'

import { WorkflowInterfaceID } from '../_ids'
import { workflowInterfaces } from '../workflow-interfaces'

import { authSchema } from './.auth-schema'
import { $interfaceFtpUserId, InterfaceFTPUserID, UserID, WorkspaceID } from './_ids'
import { users } from './user'
import { workspaces } from './workspace'

export const interfaceFtpUsers = authSchema.table(
  'interface_ftpuser',
  {
    id: id<InterfaceFTPUserID>($interfaceFtpUserId.prefix),
    username: text('username').notNull(),
    encryptedPassword: text('encrypted_password').notNull(),

    userId: refId<UserID>('user_id')
      .references(() => users.id, { onDelete: 'cascade' })
      .notNull(),

    workspaceId: refId<WorkspaceID>('workspace_id')
      .references(() => workspaces.id, { onDelete: 'cascade' })
      .notNull(),

    // Timestamp for last activity (login / upload / ...)
    lastActivityAt: timestamp('last_activity_at'),

    // Owner Workflow interface (if exists)
    interfaceId: refId<WorkflowInterfaceID>('interface_id')
      .references(() => workflowInterfaces.id, { onDelete: 'cascade' })
      .notNull(),

    ...timestamps(),
  },
  table => ({
    sftpUsernameWrspUniqueIndex: index('sftp_username_wrsp_unique_index').on(lower(table.username)),
  })
)

export const InterfaceFTPUserRecord = createSelectSchema(interfaceFtpUsers, {
  id: InterfaceFTPUserID,
  username: z.coerce.string(),
  workspaceId: WorkspaceID,
  userId: UserID,
  interfaceId: WorkflowInterfaceID,
})
export interface InterfaceFTPUserRecord extends z.infer<typeof InterfaceFTPUserRecord> {}

export const InterfaceFTPUserRecordCreate = createInsertSchema(interfaceFtpUsers, {
  username: z.coerce.string(),
  encryptedPassword: z.coerce.string(),
  userId: UserID,
  workspaceId: WorkspaceID,
  interfaceId: WorkflowInterfaceID,
}).omit({
  id: true,
  lastActivityAt: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})
export interface InterfaceFTPUserRecordCreate extends z.infer<typeof InterfaceFTPUserRecordCreate> {}

export const interfaceFtpUserRelations = relations(interfaceFtpUsers, ({ one }) => ({
  user: one(users, {
    fields: [interfaceFtpUsers.userId],
    references: [users.id],
  }),
  workspace: one(workspaces, {
    fields: [interfaceFtpUsers.workspaceId],
    references: [workspaces.id],
  }),
  workflowInterface: one(workflowInterfaces, {
    fields: [interfaceFtpUsers.interfaceId],
    references: [workflowInterfaces.id],
  }),
}))
