import { relations } from 'drizzle-orm'
import { boolean, varchar } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $samlProviderId, SAMLProviderID, SSOProviderID, WorkspaceID } from './_ids'
import { ssoProviders } from './sso-provider'
import { workspaces } from './workspace'

export const samlProviders = authSchema.table('saml_provider', {
  id: id<SAMLProviderID>($samlProviderId.prefix),
  loginUrl: varchar('login_url').notNull(),
  certificate: varchar('certificate').notNull(),
  workspaceId: refId<WorkspaceID>('workspace_id')
    .references(() => workspaces.id, { onDelete: 'cascade' })
    .notNull(),
  ssoProviderId: refId<SSOProviderID>('sso_provider_id')
    .references(() => ssoProviders.id, { onDelete: 'cascade' })
    .notNull(),
  isActive: boolean('is_active').default(true).notNull(),
  ...timestamps(),
})

export const SAMLProviderRecord = createSelectSchema(samlProviders, {
  id: SAMLProviderID,
  workspaceId: WorkspaceID,
  ssoProviderId: SSOProviderID,
})
export interface SAMLProviderRecord extends z.infer<typeof SAMLProviderRecord> {}

export const SAMLProviderRecordCreate = createInsertSchema(samlProviders, {
  workspaceId: WorkspaceID,
  ssoProviderId: SSOProviderID,
}).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface SAMLProviderRecordCreate extends z.infer<typeof SAMLProviderRecordCreate> {}

export const samlProviderRelations = relations(samlProviders, ({ one }) => ({
  workspace: one(workspaces, {
    fields: [samlProviders.workspaceId],
    references: [workspaces.id],
  }),
}))
