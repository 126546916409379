import { relations } from 'drizzle-orm'
import { text, timestamp } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $scimTokenId, ScimTokenID, WorkspaceID } from './_ids'
import { workspaces } from './workspace'

export const scimTokens = authSchema.table('scim_token', {
  id: id<ScimTokenID>($scimTokenId.prefix),
  token: text('token').unique().notNull(),
  workspaceId: refId<WorkspaceID>('workspace_id')
    .references(() => workspaces.id, { onDelete: 'cascade' })
    .notNull(),
  expiresAt: timestamp('expires_at').notNull(),
  ...timestamps(),
})

export const ScimTokenRecord = createSelectSchema(scimTokens, {
  id: ScimTokenID,
  workspaceId: WorkspaceID,
  expiresAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
})
export interface ScimTokenRecord extends z.infer<typeof ScimTokenRecord> {}

export const ScimTokenRecordCreate = createInsertSchema(scimTokens, {
  workspaceId: WorkspaceID,
}).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface ScimTokenRecordCreate extends z.infer<typeof ScimTokenRecordCreate> {}

export const scimTokenRelations = relations(scimTokens, ({ one }) => ({
  user: one(workspaces, {
    fields: [scimTokens.workspaceId],
    references: [workspaces.id],
  }),
}))
