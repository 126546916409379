import { z } from 'zod'

export const PhysicalAddressGermany = z
  .object({
    country: z.string().max(64),
    region: z.string().max(128).optional(),
    city: z.string().max(128),
    zipCode: z.string().max(12),
    street: z.string().max(256).optional(),
    houseNumber: z.string().max(64).optional(),
  })
  .openapi('PhysicalAddress')
export const PhysicalAddress = z
  .object({
    country: z.string().max(64),
    addressLine: z.string(),
  })
  .openapi('PhysicalAddressLine')

export const PhysicalAddressType = z.union([PhysicalAddressGermany, PhysicalAddress])
export type PhysicalAddressType = z.infer<typeof PhysicalAddressType>

const FirstName = z.string().max(512)
const LastName = z.string().max(512)
const Email = z.string().email().max(512)
const Phone = z.string().max(15)
const Social = z.string().max(512)
const Website = z.string().max(512)
const Department = z.string().max(512)
const PersonFunction = z.string().max(512)
const Notes = z.string().max(10_000)
const beA = z.string().max(128)
const Peppol = z.string().max(128)
const KIM = z.string().max(128)
const eBO = z.string().max(128)
const InvoiceFormat = z.enum(['pdf', 'xml', 'zugferd'])

export const ContactProperties = z
  .union([
    z.object({ address: PhysicalAddressType }),
    z.object({ firstName: FirstName }),
    z.object({ lastName: LastName }),
    z.object({ email: Email }),
    z.object({ phone: Phone }),
    z.object({ website: Website }),
    z.object({ social: Social }),
    z.object({ department: Department }),
    z.object({ function: PersonFunction }),
    z.object({ notes: Notes }),
    z.object({ beA: beA }),
    z.object({ peppol: Peppol }),
    z.object({ KIM: KIM }),
    z.object({ eBO: eBO }),
    z.object({ invoiceFormat: InvoiceFormat }),
  ])
  .openapi('ContactProperties')
export type ContactProperties = z.infer<typeof ContactProperties>

export const PhysicalAddressGermanyRequired = z
  .object({
    country: z.string().max(64),
    region: z.string().max(128),
    city: z.string().max(128),
    zipCode: z.string().max(12),
    street: z.string().max(256),
    houseNumber: z.string().max(64),
  })
  .openapi('PhysicalAddressGermanyRequired')

export const PhysicalAddressBasedOnCountry = z
  .union([PhysicalAddressGermanyRequired, PhysicalAddress])
  .superRefine((data, ctx) => {
    if (data.country === 'DE') {
      const germanyValidation = PhysicalAddressGermanyRequired.safeParse(data)
      if (!germanyValidation.success) {
        for (const error of germanyValidation.error.errors) {
          ctx.addIssue(error)
        }
      }
    } else {
      const generalValidation = PhysicalAddress.safeParse(data)
      if (!generalValidation.success) {
        for (const error of generalValidation.error.errors) {
          ctx.addIssue(error)
        }
      }
    }
  })

export type PhysicalAddressBasedOnCountry = z.infer<typeof PhysicalAddressBasedOnCountry>
