import { InferInsertModel, InferSelectModel, relations } from 'drizzle-orm'
import { jsonb, pgTable, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { pkPart, refId, timestamps } from '@epostbox/shared/database'

import { $workflowOutputId, WorkflowID, WorkflowOutputID, WorkflowRunID } from './_ids'
import { workflow } from './workflow'
import { workflowRun } from './workflow-run'

export const workflowOutput = pgTable('workflow_output', {
  id: pkPart<WorkflowOutputID>($workflowOutputId.prefix),
  workflowId: refId<WorkflowID>('workflow_id')
    .references(() => workflow.id, { onDelete: 'cascade' })
    .notNull(),
  workflowRunId: refId<WorkflowRunID>('workflow_run_id')
    .references(() => workflowRun.id, { onDelete: 'cascade' })
    .notNull(),
  stateBefore: text('state_before').notNull(), // an ulid = id of the state before
  stateAfter: text('state_after').notNull(), // an ulid = id of the state after
  output: jsonb('output'), //TODO: type
  ...timestamps(),
})

const WorkflowOutputSchema = {
  id: WorkflowOutputID,
  workflowId: WorkflowID,
  workflowRunId: WorkflowRunID,
}
export const WorkflowOutputRecord = createSelectSchema(workflowOutput, WorkflowOutputSchema)
export type WorkflowOutputRecord = InferSelectModel<typeof workflowOutput>

export const WorkflowOutputRecordCreate = createInsertSchema(workflowOutput, WorkflowOutputSchema)
export type WorkflowOutputRecordCreate = InferInsertModel<typeof workflowOutput>

export const workflowOutputRelations = relations(workflowOutput, ({ one }) => ({
  workflowRun: one(workflowRun, {
    fields: [workflowOutput.workflowRunId],
    references: [workflowRun.id],
  }),
  workflow: one(workflow, {
    fields: [workflowOutput.workflowId],
    references: [workflow.id],
  }),
}))
