import { index, integer, pgTable, primaryKey, text, varchar } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { pkPart, refId, timestamps } from '@epostbox/shared/database'

import { $signatureId, SignatureID } from './_ids'
import { UserID, WorkspaceID } from './auth/_ids'

export const signatures = pgTable(
  'signatures',
  {
    id: pkPart<SignatureID>($signatureId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),

    createdBy: refId<UserID>('created_by').notNull(),
    name: text('name').notNull(),
    key: text('key').notNull(),
    fileName: text('file_name').notNull(),
    mimeType: text('mime_type').notNull(),
    size: integer('size').notNull(),
    width: integer('width'),
    height: integer('height'),
    status: varchar('status', { enum: ['pending', 'uploaded'] })
      .default('pending')
      .notNull(),
    ...timestamps(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
    keyIndex: index('signatures_key_index').on(table.key),
  })
)

const SignatureSchema = {
  id: SignatureID,
  workspaceId: WorkspaceID,
  createdBy: UserID,
}

export const SignatureRecord = createSelectSchema(signatures, SignatureSchema)
export type SignatureRecord = z.infer<typeof SignatureRecord>

export const SignatureRecordCreate = createInsertSchema(signatures, SignatureSchema).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})
export type SignatureRecordCreate = z.infer<typeof SignatureRecordCreate>
