import { relations } from 'drizzle-orm'
import { text, varchar, integer, timestamp } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $emailAccountId, EmailAccountID, UserID, WorkspaceID } from './_ids'
import { users } from './user'
import { workspaces } from './workspace'

export const emailAccounts = authSchema.table('email_accounts', {
  id: id<EmailAccountID>($emailAccountId.prefix),
  userId: refId<UserID>('user_id')
    .references(() => users.id, { onDelete: 'cascade' })
    .notNull(),
  workspaceId: refId<WorkspaceID>('workspace_id')
    .references(() => workspaces.id, { onDelete: 'cascade' })
    .notNull(),
  email: text('email').notNull(),
  displayName: text('display_name'),
  password: text('password'),
  passwordIv: text('password_iv'),
  domain: text('domain'),
  host: text('host'),
  port: integer('port'),
  smtpHost: text('stmp_host'),
  smtpPort: integer('smtp_port'),
  protocol: varchar('protocol', {
    enum: ['imap', 'ews'],
  }).default('imap'),
  status: varchar('status', {
    enum: ['OK', 'FAILED'],
  }).default('OK'),
  refreshToken: text('refresh_token'),
  lastFetchDate: timestamp('last_fetch_date').notNull(),
  lastResultId: integer('last_result_id'),

  ...timestamps(),
})

export const EmailAccountRecord = createSelectSchema(emailAccounts, { id: EmailAccountID, userId: UserID })
export interface EmailAccountRecord extends z.infer<typeof EmailAccountRecord> {}

export const EmailAccountRecordCreate = createInsertSchema(emailAccounts, {
  userId: UserID,
  workspaceId: WorkspaceID,
}).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface EmailAccountRecordCreate extends z.infer<typeof EmailAccountRecordCreate> {}

export const EmailAccountIdQueueRecord = z.object({
  emailAccountId: EmailAccountID,
})
export const EmailAccountIdQueueArray = z.array(EmailAccountIdQueueRecord)
export type EmailAccountIdQueueArray = z.infer<typeof EmailAccountIdQueueArray>

export const emailAccountRelations = relations(emailAccounts, ({ one }) => ({
  user: one(users, {
    fields: [emailAccounts.userId],
    references: [users.id],
  }),
  workspace: one(workspaces, { fields: [emailAccounts.workspaceId], references: [workspaces.id] }),
}))
