import { pgTable, text, jsonb } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, timestamps } from '@epostbox/shared/database'

import { $busEventId, BusEventID } from './_ids'

type PayloadValue = string | number | object | PayloadValue[]
type EventPayloadType = Record<string, PayloadValue>

export const busEvents = pgTable('bus_events', {
  id: id<BusEventID>($busEventId.prefix),

  // The name of event;
  // Follows the pattern "[namespace]:[action]" (e.g. "documents:remove", "sftp-user:create")
  // See "[Keyflowz-Core]/events/index.ts"
  name: text('name').notNull(),

  // Payload of current event, if provided. Empty object if no payload
  payload: jsonb('payload').$type<EventPayloadType>().notNull().default({}),

  ...timestamps(),
})

export const BusEventRecord = createSelectSchema(busEvents, {
  id: BusEventID,
  name: z.string(),
  payload: z.any().default({}), // z.object<EventPayloadType>(???).default({}),
  createdAt: z.date(),
})
export type BusEventRecord = z.infer<typeof BusEventRecord>

export const BusEventRecordCreate = createInsertSchema(busEvents, {
  id: BusEventID,
  name: z.string().min(3),
  payload: z.any().default({}),
  createdAt: z.date().default(new Date()),
})
export type BusEventRecordCreate = z.infer<typeof BusEventRecordCreate>
