import { InferInsertModel, InferSelectModel } from 'drizzle-orm'
import { pgEnum, pgTable, text, timestamp } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { $workflowInterfaceId, WorkflowInterfaceID } from './_ids'
import { UserID, WorkspaceID } from './auth/_ids'
import { users } from './auth/user'
import { workspaces } from './auth/workspace'

export const WorkflowInterfaceType = {
  REST: 'REST-API',
  SFTP: 'SFTP',
} as const
export type WorkflowInterfaceType = (typeof WorkflowInterfaceType)[keyof typeof WorkflowInterfaceType]
export const wInterfaceTypeEnum = pgEnum('workflow_interface_type_enum', [
  WorkflowInterfaceType.REST,
  WorkflowInterfaceType.SFTP,
])

export const WorkflowInterfaceStatus = {
  SetUp: 'Set up',
  Incomplete: 'Incomplete',
  Expired: 'Expired',
} as const
export type WorkflowInterfaceStatus = (typeof WorkflowInterfaceStatus)[keyof typeof WorkflowInterfaceStatus]
export const wInterfaceStatusEnum = pgEnum('workflow_interface_status_enum', [
  WorkflowInterfaceStatus.SetUp,
  WorkflowInterfaceStatus.Incomplete,
  WorkflowInterfaceStatus.Expired,
])

export const workflowInterfaces = pgTable('workflow_interface', {
  id: id<WorkflowInterfaceID>($workflowInterfaceId.prefix),

  name: text('name').notNull(),
  type: wInterfaceTypeEnum('type').notNull(),
  status: wInterfaceStatusEnum('status').notNull().default(WorkflowInterfaceStatus.Incomplete),

  // Interface access expiration date (if provided, null if not provided)
  expireAt: timestamp('expire_at'),

  workspaceId: refId<WorkspaceID>('workspace_id')
    .notNull()
    .references(() => workspaces.id, { onDelete: 'cascade' }),

  createdBy: refId<UserID>('created_by')
    // TODO: Mark as ".notNull()" at some point in the future; was commented for successfully migrations purposes
    // .notNull()
    .references(() => users.id, { onDelete: 'cascade' }),

  ...timestamps(),
})

const workflowInterfaceSchema = {
  id: WorkflowInterfaceID,
  name: z.string(),
  type: z.enum([WorkflowInterfaceType.REST, WorkflowInterfaceType.SFTP]),
  status: z.enum([WorkflowInterfaceStatus.SetUp, WorkflowInterfaceStatus.Incomplete, WorkflowInterfaceStatus.Expired]),
  workspaceId: WorkspaceID,
  createdBy: UserID,
  expireAt: z.date().optional(),
}

export const WorkflowInterfaceRecord = createSelectSchema(workflowInterfaces, workflowInterfaceSchema)
export type WorkflowInterfaceRecord = InferSelectModel<typeof workflowInterfaces>

export const WorkflowInterfaceCreate = createInsertSchema(workflowInterfaces, workflowInterfaceSchema)
export type WorkflowInterfaceCreate = InferInsertModel<typeof workflowInterfaces>
