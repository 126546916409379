import { useQueryClient } from '@tanstack/vue-query'
import { listen, TauriEvent } from '@tauri-apps/api/event'
import { Child, Command, open } from '@tauri-apps/api/shell'
import { invoke } from '@tauri-apps/api/tauri'
import { appWindow } from '@tauri-apps/api/window'
import destr from 'destr'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

interface PrinterEvents {
  name: 'new_job' | 'preview_uploaded' | 'job_done' | 'error'
  job: {
    id: number
    name: string
  }
  doc_id?: string
}

const jobs = ref(new Map<number, PrinterEvents>())

let printerWS: WebSocket | undefined
let wsRetries = 0
const childProcess = ref<Child>()

export function useNative() {
  const router = useRouter()
  const queryClient = useQueryClient()
  const isNative = '__TAURI_IPC__' in window

  async function init() {
    if (!isNative) return

    if (!import.meta.env.DEV) {
      const sidecar = Command.sidecar('binaries/printer')

      sidecar.stdout.on('data', data => {
        console.log(`sidecar:`, data)
      })
      sidecar.stderr.on('data', data => {
        console.error('sidecar:', data)
      })
      childProcess.value = await sidecar.spawn()
      // @ts-expect-error needed for debugging
      window.child = childProcess.value
      console.log('Sidecar pid:', childProcess.value.pid)

      listen(TauriEvent.WINDOW_DESTROYED, function () {
        childProcess.value?.kill()
      })

      listen('tauri://close-requested', () => {
        console.log('close requested')
      })
    }

    printerWS = new WebSocket('ws://localhost:5000')
    // @ts-expect-error needed for debugging
    window.printer = printerWS

    printerWS.addEventListener('message', async event => {
      const ev = destr<PrinterEvents>(event.data)
      jobs.value.set(ev.job.id, ev)

      if (ev.name === 'new_job') {
        await invoke('focus_window')
        router.push(`/print-queue`)
      }
      if (ev.name === 'preview_uploaded') {
        await invoke('focus_window')
        router.push(`/workbench/drafts/d/${ev.doc_id}`)
      }
    })

    appWindow.listen('scheme-request-received', async ({ payload: url }: { payload: string }) => {
      await focusWindow()
      const pkceCode = new URL(url)

      location.search = pkceCode.search
      if (pkceCode) {
        queryClient.invalidateQueries({ queryKey: ['session'] })
      }
    })

    disableRefresh()
  }

  async function focusWindow() {
    if (!isNative) return
    await invoke('focus_window')
  }

  async function openExternal(url: string) {
    if (!isNative) return
    await open(url)
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  async function sendMessage(messageObject: unknown) {
    if (printerWS?.readyState !== WebSocket.OPEN) {
      wsRetries += 1
      console.warn('could not send websocket message because the connection is not open', messageObject)
      console.log(printerWS)

      if (wsRetries === 5) {
        wsRetries = 0
        return
      }
      setTimeout(() => {
        sendMessage(messageObject)
      }, 1000)
      return
    }

    printerWS.send(JSON.stringify(messageObject))
    wsRetries = 0
  }

  function withCredentials(rawUrl: string) {
    if (!isNative) return rawUrl
    const url = new URL(rawUrl)
    url.searchParams.append('token', localStorage.getItem('refresh_token')!)
    return url.toString()
  }

  return {
    isNative,
    init,
    focusWindow,
    openExternal,
    sendMessage,
    withCredentials,
    jobs,
  }
}

function disableRefresh() {
  document.addEventListener('keydown', function (event) {
    // Prevent F5 or Ctrl+R (Windows/Linux) and Command+R (Mac) from refreshing the page
    if (event.key === 'F5' || (event.ctrlKey && event.key === 'r') || (event.metaKey && event.key === 'r')) {
      event.preventDefault()
    }
    if (event.key === 'P' && event.ctrlKey) event.preventDefault()
    if (event.key === 'F5' && event.ctrlKey && event.shiftKey) event.preventDefault()
  })

  // Disable native context menu.
  document.addEventListener('contextmenu', function (event) {
    event.preventDefault()
  })
}
