import { z } from '@hono/zod-openapi'
import { relations } from 'drizzle-orm'
import { text, timestamp } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { WorkflowInterfaceID } from '../_ids'
import { workflowInterfaces } from '../workflow-interfaces'

import { authSchema } from './.auth-schema'
import { $interfaceRestApiUserId, InterfaceRestApiUserID } from './_ids'

export const interfaceRestApiUsers = authSchema.table('interface_restapiuser', {
  id: id<InterfaceRestApiUserID>($interfaceRestApiUserId.prefix),

  // The API token used in uploading documents via REST-API interface
  apiToken: text('api_token').notNull(),

  // Token expire date (if provided)
  expireAt: timestamp('expire_at'),

  // Owner interface (if exists)
  interfaceId: refId<WorkflowInterfaceID>('interface_id')
    .references(() => workflowInterfaces.id, { onDelete: 'cascade' })
    .notNull(),

  ...timestamps(),
})

export const InterfaceRestApiUserRecord = createSelectSchema(interfaceRestApiUsers, {
  id: InterfaceRestApiUserID,
  apiToken: z.string(),
  expireAt: z.coerce.date().optional().nullable(),
  interfaceId: WorkflowInterfaceID,
})
export interface InterfaceRestApiUserRecord extends z.infer<typeof InterfaceRestApiUserRecord> {}

export const InterfaceRestApiUserRecordCreate = createInsertSchema(interfaceRestApiUsers, {
  apiToken: z.coerce.string(),
  expireAt: z.coerce.date().optional().nullable(),
  interfaceId: WorkflowInterfaceID,
}).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})
export interface InterfaceRestApiUserRecordCreate extends z.infer<typeof InterfaceRestApiUserRecordCreate> {}

export const interfaceRestApiUserRelations = relations(interfaceRestApiUsers, ({ one }) => ({
  workflowInterface: one(workflowInterfaces, {
    fields: [interfaceRestApiUsers.interfaceId],
    references: [workflowInterfaces.id],
  }),
}))
