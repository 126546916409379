import { relations } from 'drizzle-orm'
import { timestamp, varchar } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { authSchema } from './.auth-schema'
import { $identityId, IdentityID, UserID } from './_ids'
import { users } from './user'

export const IdentityProvider = {
  PASSWORD: 'password',
  SAML: 'saml',
} as const
export type IdentityProvider = (typeof IdentityProvider)[keyof typeof IdentityProvider]

/** A user can have multiple identities based on their provider. So the same user initially can log in with an email + password, and later they will log in using SSO after it's configured. */
export const identities = authSchema.table('identity', {
  id: id<IdentityID>($identityId.prefix),
  userId: refId<UserID>('user_id')
    .references(() => users.id, { onDelete: 'cascade' })
    .notNull(),

  /** e.g. `password`, `saml`, etc. */
  provider: varchar('provider', { enum: [IdentityProvider.PASSWORD, IdentityProvider.SAML] }).notNull(),

  lastLoginAt: timestamp('last_login_at'),
  ...timestamps(),
})

export const IdentityRecord = createSelectSchema(identities, { id: IdentityID, userId: UserID })
export interface IdentityRecord extends z.infer<typeof IdentityRecord> {}

export const IdentityRecordCreate = createInsertSchema(identities, { userId: UserID }).omit({
  id: true,
  createdAt: true,
  deletedAt: true,
  updatedAt: true,
})
export interface IdentityRecordCreate extends z.infer<typeof IdentityRecordCreate> {}

export const identityRelations = relations(identities, ({ one }) => ({
  user: one(users, {
    fields: [identities.userId],
    references: [users.id],
  }),
}))
