import { RouteRecordRaw } from 'vue-router'

import Index from './views/index.vue'

export const routes: RouteRecordRaw[] = [
  {
    name: 'workspace-administration',
    path: '/administration',
    component: Index,
    meta: {
      title: 'administration',
      module: 'administration',
    },
    children: [
      {
        path: '',
        redirect() {
          return 'general'
        },
        children: [],
      },
      {
        path: 'general',
        name: 'administration-general',
        component: () => import('./views/general.vue'),
        meta: {
          title: 'administration-general',
          module: 'administration',
        },
      },
      {
        path: 'sso-settings',
        name: 'administration-sso',
        component: () => import('./views/sso.vue'),
        meta: {
          title: 'administration-sso',
          module: 'administration',
        },
      },
      {
        path: 'workflows',
        name: 'administration-workflows',
        component: () => import('./views/workflows.vue'),
        meta: {
          title: 'administration-workflows',
          module: 'administration',
        },
      },
      {
        path: 'cost-centers',
        name: 'administration-cost-centers',
        component: () => import('./views/cost-centers.vue'),
        meta: {
          title: 'administration-cost-centers',
          module: 'administration',
        },
      },
      {
        path: 'interfaces',
        name: 'administration-interfaces',
        component: () => import('./views/interfaces.vue'),
        meta: {
          title: 'administration-interfaces',
          module: 'administration',
        },
      },
      {
        path: 'workflow/:id',
        name: 'administration-workflow',
        component: () => import('./views/workflow.vue'),
        meta: {
          title: 'administration-workflow',
          module: 'administration',
          moduleId: 'workspace-administration',
          parent: 'administration-workflows',
        },
      },
      {
        path: 'users',
        name: 'administration-users',
        component: () => import('./views/users.vue'),
        meta: {
          title: 'administration-users',
          module: 'administration',
        },
      },
      {
        path: 'signatures',
        name: 'administration-signatures',
        component: () => import('./views/signatures.vue'),
        meta: {
          title: 'administration-signatures',
          module: 'administration',
        },
      },
      {
        path: 'letterheads',
        name: 'administration-letterheads',
        component: () => import('./views/letterheads.vue'),
        meta: {
          title: 'administration-letterheads',
          module: 'administration',
        },
      },
      {
        path: 'attachments',
        name: 'administration-attachments',
        component: () => import('./views/attachments.vue'),
        meta: {
          title: 'administration-attachments',
          module: 'administration',
        },
      },
      {
        path: 'text-templates',
        name: 'administration-text-templates',
        component: () => import('./views/text-templates.vue'),
        meta: {
          title: 'administration-text-templates',
          module: 'administration',
        },
      },
    ],
  },
  {
    name: 'settings',
    path: '/settings',
    component: () => import('./views/settings.vue'),
    meta: {
      title: 'workspace-settings',
      module: 'workspace',
    },
  },
  {
    name: 'download',
    path: '/download',
    component: () => import('./views/download.vue'),
    meta: {
      title: 'workspace-download',
      module: 'workspace',
    },
  },
  {
    name: 'tutorials',
    path: '/tutorials',
    component: () => import('./views/tutorials.vue'),
    meta: {
      title: 'workspace-tutorials',
      module: 'workspace',
    },
  },
  {
    name: 'support',
    path: '/support',
    component: () => import('./views/support.vue'),
    meta: {
      title: 'workspace-support',
      module: 'workspace',
    },
  },
]
